import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, LinearProgress, TextField, Typography } from '@mui/material'
import { setControls, useControls } from '../../utils/useControls'

export function Refresh() {
  const { nowMode, refreshRate, interval } = useControls('nowMode', 'refreshRate', 'interval')

  const [progress, setProgress] = React.useState(0)
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(Date.now)

  const refreshData = () => {
    const now = Math.floor(Date.now() / 1000)
    setControls({
      startTime: now - interval,
      endTime: now,
    })
    setLastUpdatedAt(Date.now())
    setProgress(0)
  }
  const toggleNowMode = () => {
    const becomesActive = !nowMode
    setControls({ nowMode: !nowMode })
    if (becomesActive) {
      refreshData()
    } else {
      setProgress(0)
    }
  }
  const setRefreshRate = (e) => {
    setControls({ refreshRate: e.target.value })
  }

  // Handle nowMode
  React.useEffect(() => {
    const poll = setInterval(() => {
      if (!nowMode) {
        clearInterval(poll)
        return
      }
      const elapsed = Date.now() - lastUpdatedAt
      const newProgress = Math.floor((elapsed / (1000 * refreshRate)) * 100)
      if (newProgress > 99) {
        setProgress(0)
        refreshData()
      } else {
        setProgress(newProgress)
      }
    }, 16)

    return () => clearInterval(poll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowMode, refreshRate, lastUpdatedAt])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography>
          <b>
            Refresh Rate <small>(seconds)</small>
          </b>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', my: 1, alignItems: 'center' }}>
        <TextField
          inputProps={{
            type: 'number',
          }}
          sx={{ width: 80, mr: 1 }}
          value={refreshRate}
          onChange={setRefreshRate}
          size="small"
        />
        <Button
          onClick={toggleNowMode}
          color="primary"
          startIcon={<RefreshIcon />}
          variant="outlined"
        >
          {nowMode ? 'Stop Refreshing' : 'Start Refreshing'}
        </Button>
      </Box>
      <Box sx={{ width: '100%', visibility: nowMode ? 'visible' : 'hidden' }}>
        <LinearProgress value={progress} variant="determinate" />
      </Box>
    </Box>
  )
}

Refresh.propTypes = {}
