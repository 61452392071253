import React, { useState } from 'react'
import { IgnoreKeys } from 'react-hotkeys'
import Close from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import LoadingIndicator from '../LoadingIndicator'

const Feedback = ({ isOpen, toggle }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [comment, setComment] = useState('')
  const [email, setEmail] = useState('')

  const sendMessage = () => {
    const { href, protocol, host } = window.location
    // Creates a link to `href` with the path root as text
    const currentPath = `<${href}|${protocol}//${host}...>`
    const message =
      `\n*Current Path:* ${currentPath}\n` +
      `${email ? `\n*Email:* ${email}\n` : ''}` +
      `\n*Comment:* ${comment}`

    // TODO: Pull this out into .env variable
    const slackUrl = 'T704Y7S4E/B04J215DZPB/o8aXhBOabj80UyZ82TIHEG8M'

    const data = {
      channel: '#edge-insights-feedback',
      username: 'EdgeInsightsUser',
      text: message,
    }

    return fetch(`https://hooks.slack.com/services/${slackUrl}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!comment) {
      return
    }

    setIsLoading(true)

    try {
      await sendMessage()
      setComment('')
      setEmail('')
      toggle(false)
    } catch (error) {
      console.error(`There was an error submitting feedback: ${error}`)
    }
    setIsLoading(false)
  }

  return (
    <IgnoreKeys>
      <Dialog open={isOpen} onClose={toggle}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          How can we improve?
          <Tooltip title="Close">
            <IconButton onClick={toggle} size="small">
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <LoadingIndicator loading={isLoading} />
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
            <TextField
              label="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Send us your feedback, comments, and suggestions!"
              multiline
              rows={6}
              value={comment}
              sx={{ width: 500 }}
              onChange={(e) => setComment(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle}>Cancel</Button>
          <Button disabled={!comment} onClick={onSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </IgnoreKeys>
  )
}

export default Feedback
