import { useSession } from 'next-auth/react'
import React, { useContext, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import AppContext from '../../AppContext'
import { getContext } from '../../utils/dataFormatting'
import isStaff from '../../utils/isStaff'
import { applyDataSourceDefaults, useControls } from '../../utils/useControls'
import HotKeySelect from '../HotKeySelect'

export function SourceSelector() {
  const { dataSources: sourceOptions } = useContext(AppContext)
  const { dataSource } = useControls('dataSource')
  const staff = isStaff(useSession())
  const consoleContext = getContext()

  const options = useMemo(() => {
    let options = sourceOptions

    if (window.location.hostname === 'edge-insights.edgio.app') {
      // dont show staging data source in production
      options = options.filter((option) => !option.staging)
    }

    if (!staff) {
      options = options.filter((option) => !option.staffOnly)
    }

    return options
  }, [staff, sourceOptions, consoleContext])

  const selectSource = (event) => {
    const { value } = event.target
    const dataSource = options.find((item) => item.value === value)
    applyDataSourceDefaults(dataSource)
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Typography sx={{ mb: 1 }}>
        <b>Data Source</b>
      </Typography>
      <HotKeySelect
        size="small"
        value={dataSource}
        onChange={selectSource}
        hotKeyHelp="Show the data source options"
        hotKeyName="SOURCE"
        hotKeySequence="d"
        fullWidth
      >
        {options.map((option, idx) => (
          <MenuItem
            key={option.value + idx}
            value={option.value}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography fontWeight={500}>{option.label}</Typography>
            {option.description && (
              <Typography variant="caption" sx={{ opacity: 0.6 }} component="div">
                {option.description}
              </Typography>
            )}
          </MenuItem>
        ))}
      </HotKeySelect>
    </Box>
  )
}
