import { toast } from 'react-toastify'
import { signIn } from '../SessionProvider'
import { logError } from '../airbrake'
import { chartTypes } from '../components/Dashboard/Analytics'
import { formatParams, getContext } from './dataFormatting'

// Comprehensive error handler for various types of error responses
const throwError = async (response, url) => {
  const { status } = response
  if (status === 403) {
    const error = await response.text()
    const idsTerminatorForbiddenPattern =
      'client [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12} is requesting data for [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12} which is forbidden'
    if (!error.match(idsTerminatorForbiddenPattern)) {
      signIn()
    } else if (url.includes('/log')) {
      toast.error('403 forbidden')
    }
  } else if (status === 401) {
    signIn()
  } else if (status === 400 /* Bad Request */) {
    const { errors } = await response.json()
    const error = errors[0]
    const formatMessage = () => {
      if (error.message) {
        return error.message
      } else if (error.details?.length) {
        if (error.details.length > 1) {
          return error.details
            .map(({ description }, index) => `${index + 1}. ${description}`)
            .join(', ')
        }
        return error.details[0].description
      } else if (error.hasOwnProperty('title')) {
        return error.title
      } else if (typeof error === 'string') {
        return error
      }
    }

    const message = `Error ${status}: ${formatMessage()}`
    logError(error, { url, message })
    toast.error(message, { toastId: '400error' })
  } else {
    const message = `Error ${status}: ${response.statusText}`
    const error = new Error(message)
    logError(error, { url, message })
    const { errors } = await response.json()
    if (errors[0]?.message.includes('Elastic Search took longer than  10.00')) {
      toast.error('Can not collect analytics, specify filters or decrease time period')
    } else {
      toast.error(error.message, { toastId: '500error' })
    }
  }
}

// Mimics `allSettled` without swallowing/overriding errors. Continues fetching
// even if one of the promises throws.
export const fetchMultiple = (fetches = []) =>
  Promise.all(
    fetches.map((promise) =>
      promise.catch((error) => {
        throw error
      }),
    ),
  )

// Converts params from app values into a string to be appended to fetch routes
export const serializeParams = (params, dataSource) => {
  return Object.entries(formatParams(params, dataSource))
    .filter(
      ([key, value]) =>
        ![undefined, 'undefined'].includes(key) & ![undefined, 'undefined'].includes(value),
    )
    .map(([key, value]) => {
      if (key === 'filters') {
        Object.keys(value).forEach((key) =>
          key === 'undefined' || value[key] === undefined ? delete value[key] : {},
        )
        return `${key}=${encodeURIComponent(JSON.stringify(value))}`
      }
      const [encodedKey, encodedValue] = [key, value].map(encodeURIComponent)
      return `${encodedKey}=${encodedValue}`
    })
    .join('&')
}

/**
 * Injects the an filter into the params if provided on the context query param passed from
 * the Edgio console.
 *
 * The context query param is base64 of the form:
 *  {
 *    team: "Team Name",
 *    site: "Site Name",
 *    environment: "Environment Name",
 *    an: "accountNumber",
 *  }
 * @param {*} params
 * @returns
 */
export const teamIdFieldNameMap = {
  default: 'team_id',
  'bassfish/nel': 'organization_id',
  'bassfish/csp': 'TeamId',
  'bassfish/stage/csp': 'TeamId',
}
export const anFieldNameMap = { default: 'an', 'bassfish/csp': 'An', 'bassfish/stage/csp': 'An' }
function injectContext(datasource, params) {
  if (!params || datasource.startsWith('console/')) {
    return params
  }

  const context = getContext()
  const arr = []

  if (context?.team) {
    arr.push({
      active: true,
      field: teamIdFieldNameMap[datasource]
        ? teamIdFieldNameMap[datasource]
        : teamIdFieldNameMap.default,
      value: context.team.idsTenantId,
    })
  }

  // if (context?.environment) {
  //   arr.push({
  //     active: true,
  //     field: 'environment_id',
  //     value: context.environment.idsTenantId,
  //   })
  // }
  if (
    context?.environment &&
    ['serverlesslog', 'rumlog', 'edgefunctionslog', 'bassfish/nel'].includes(datasource)
  ) {
    arr.push({
      active: true,
      field: 'environment_id',
      value: context.environment.idsTenantId,
      operatorId: 'eq',
    })
  } else if (context?.an) {
    arr.push({
      active: true,
      field: anFieldNameMap[datasource] ? anFieldNameMap[datasource] : anFieldNameMap.default,
      value: context.an,
      operatorId: 'eq',
    })
  }

  return {
    ...params,
    filters: [...(params.filters || []), ...arr],
  }
}

const apiVersion = 'v0.7'
const analyticsApiVersion = 'v1.0'

export function getConsoleURLBase() {
  return process.env.CONSOLE_URL_BASE || 'https://edgio.app'
}

export const getAppData = async (dataSource, endpoint, params, ctr) => {
  if (
    (dataSource === 'analytics' && !chartTypes.some((i) => i.value === endpoint)) ||
    dataSource === 'ecMode'
  ) {
    dataSource = 'accesslog'
  }

  const v = dataSource === 'analytics' ? analyticsApiVersion : apiVersion

  const paramString = params
    ? `?${serializeParams(injectContext(dataSource, params), dataSource)}`
    : ''

  // Connect through the proxy
  let urlBase = `/api/proxy/${v}/${dataSource}`

  // uncomment this to connect dirctly to the API
  // const urlBase = `/cdn/edge-insights/${v}/${dataSource}/${endpoint}${paramString}`

  // connect directly to console to use the session cookie for auth
  if (dataSource.startsWith('console/')) {
    const slug = dataSource.replace(/^console\//, '')
    urlBase = `${process.env.CONSOLE_URL_BASE}/edge-insights/datasources/${slug}`
  }

  const url = `${urlBase}/${endpoint}${paramString}`

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      // uncomment this to connect dirctly to the API
      // Accept: 'application/json',
      // Authorization: `Bearer ${window.session.user.accessToken}`,
    },
    // uncomment this to connect dirctly to the API
    credentials: 'include',
    method: 'GET',
    ...(ctr && { signal: ctr.signal }),
  })

  if (!response.ok) {
    await throwError(response, url)
  }

  const data = await response.json()
  const link = await response.url
  return { link, data }
}
