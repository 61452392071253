import { Box, useTheme } from '@mui/material'

export default function CardHeader(props) {
  const theme = useTheme()
  return (
    <Box
      {...props}
      sx={{
        p: '12px 16px',
        pb: '11px',
        ...props.sx,
        background: theme.palette.background.header,
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    />
  )
}
