import { memo, useEffect, useState } from 'react'
import Icon from '@mui/icons-material/DataObject'
import { IconButton, Tooltip } from '@mui/material'

function DownloadDataLink({ data, name, ...props }) {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    setUrl((url) => {
      if (url) {
        window.URL.revokeObjectURL(url)
      }

      return window.URL.createObjectURL(
        new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }),
      )
    })

    return () => {
      setUrl((url) => {
        if (url) {
          window.URL.revokeObjectURL(url)
        }
        return url
      })
    }
  }, [data])

  return (
    <Tooltip
      title={`View the underlying JSON data ${
        name ? 'for ' + name + ' ' : ' '
      }in a new browser tab.`}
      arrow
    >
      <IconButton
        {...props}
        size="small"
        href={url}
        target="_blank"
        rel="noreferrer"
        sx={{
          opacity: 0.6,
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  )
}

export default memo(DownloadDataLink)
