import React, { useEffect, useState } from 'react'
import { getApplicationKeyMap } from 'react-hotkeys'
import Close from '@mui/icons-material/Close'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'

const HotkeyHelp = ({ isOpen, toggle }) => {
  const [keyMap, setKayMap] = useState({})

  useEffect(() => {
    const ordered = {}
    const unordered = getApplicationKeyMap()
    Object.keys(unordered)
      .sort()
      .forEach((k) => {
        ordered[k] = unordered[k]
      })
    setKayMap(ordered)
  }, [])

  return (
    <Dialog open={isOpen} onClose={toggle}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Hot Keys
        <Tooltip title="Close">
          <IconButton onClick={toggle}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ overflowY: 'auto' }}>
        <Table size="small">
          <TableBody>
            {Object.keys(keyMap).map((k) => (
              <TableRow key={k}>
                <TableCell>
                  <kbd>{keyMap[k].sequences[0].sequence}</kbd>
                </TableCell>
                <TableCell>
                  <Typography>{keyMap[k].name}</Typography>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <kbd>click+legend</kbd>
              </TableCell>
              <TableCell>
                <Typography>Deselect legend as negative filter</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <kbd>shift+click+legend</kbd>
              </TableCell>
              <TableCell>
                <Typography>Select legend as positive filter</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <kbd>click+pieChart</kbd>
              </TableCell>
              <TableCell>
                <Typography>Select legend as positive filter</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <kbd>shift+click+pieChart</kbd>
              </TableCell>
              <TableCell>
                <Typography>Select slice as negative filter</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}

HotkeyHelp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default HotkeyHelp
