import React, { useRef, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import Add from '@mui/icons-material/Add'
import { Box, Button, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useControls } from '../../../utils/useControls'
import FilterOption from './FilterOption'

const Filters = ({ fields, datasource }) => {
  const { filters } = useControls('filters')
  const allFilters = [...filters]

  // filterToEdit can be false, null, or the string value of a `field`
  // false: editing nothing
  // null: editing a newly "Add"ed filter
  // string: editing filter with matching `field` value
  const [filterToEdit, setFilterToEdit] = useState(false)

  const selectedFields = allFilters.map(({ field }) => field)

  const fieldInputRef = useRef()
  const openAddIfClosed = (e) => {
    if (filterToEdit === false) {
      setFilterToEdit(null)
      e.preventDefault()
      fieldInputRef.current?.focus()
    }
  }

  const keyMap = {
    FILTER: { name: 'Add Filter', sequence: '/' },
  }

  const handlers = {
    FILTER: openAddIfClosed,
  }
  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges={true}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }} data-test-hook="filter-list">
        <Typography sx={{ mb: 1 }}>
          <b>Filters</b>
        </Typography>
        {allFilters.map((filter, index) => (
          <FilterOption
            key={filter.field}
            index={index}
            filterData={filter}
            isEditing={filterToEdit === index}
            selectFilter={setFilterToEdit}
            selectedFields={selectedFields}
            formattedFields={fields}
            existingFilters={filters}
            datasource={datasource}
          />
        ))}
        {(filterToEdit === false || filterToEdit !== null) && (
          <div>
            <Button variant="outlined" onClick={() => setFilterToEdit(null)} startIcon={<Add />}>
              Add Filter
            </Button>
          </div>
        )}
        {filterToEdit === null && (
          <FilterOption
            isEditing
            ref={fieldInputRef}
            selectFilter={setFilterToEdit}
            selectedFields={selectedFields}
            formattedFields={fields}
            existingFilters={filters}
            datasource={datasource}
          />
        )}
      </Box>
    </GlobalHotKeys>
  )
}

Filters.propTypes = {
  fields: PropTypes.array.isRequired,
  datasource: PropTypes.string.isRequired,
}

export default Filters
