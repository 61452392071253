import { Refresh } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

export default function RefreshButton({ fetchFunc }) {
  return (
    <Tooltip title="Refresh">
      <IconButton
        size="small"
        onClick={fetchFunc}
        sx={{
          opacity: 0.6,
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <Refresh />
      </IconButton>
    </Tooltip>
  )
}

RefreshButton.propTypes = {
  fetchFunc: PropTypes.func.isRequired,
}
