import React, { memo, useMemo } from 'react'
import { IgnoreKeys } from 'react-hotkeys'
import { Autocomplete, TextField, styled } from '@mui/material'
import PropTypes from 'prop-types'
import { setControls } from '../utils/useControls'

const SmallTextField = styled(TextField)(({ theme }) => ({
  '.tiny & .MuiInputBase-root': {
    fontSize: `${theme.typography.body2.fontSize} !important`,
    paddingTop: `3px !important`,
    paddingBottom: `3px !important`,
  },
}))

function FieldAutocomplete({
  control,
  blankValue,
  fields,
  value,
  placeholder,
  size,
  inputProps,
  onChange,
  ...props
}) {
  const fieldOptions = useMemo(() => {
    return fields.map((f) => ({ id: f.value, label: f.label }))
  }, [fields])

  const selectedField = useMemo(() => {
    return fields.find((f) => f.value === value)
  }, [value, fields])

  const handleChange =
    onChange ||
    ((_e, value) => {
      setControls({ [control]: value ? value.id : blankValue })
    })

  return (
    <IgnoreKeys>
      <Autocomplete
        {...props}
        autoHighlight
        value={selectedField || null}
        onChange={handleChange}
        options={fieldOptions}
        openOnFocus
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id + props.id}>
              {option.label}
            </li>
          )
        }}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        renderInput={(params) => (
          <SmallTextField {...params} placeholder={placeholder} size={size} {...inputProps} />
        )}
      />
    </IgnoreKeys>
  )
}

FieldAutocomplete.propTypes = {
  control: PropTypes.string,
  value: PropTypes.string.isRequired,
  size: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
}

FieldAutocomplete.defaultProps = {
  blankValue: null,
  placeholder: 'Select Field...',
  size: 'small',
}

export default memo(FieldAutocomplete)
