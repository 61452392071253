/**
 * Returns a copy of the original array sorted by the given property (case insensitive)
 * @param {String[]} array The array to sort
 * @param {String} property the property to sort by
 * @returns
 */
export default function sort(array, property = 'label') {
  return array.slice().sort((a, b) => {
    const aValue = a[property],
      bValue = b[property]
    if (typeof aValue === 'number') {
      return aValue - bValue
    } else if (typeof aValue === 'string') {
      return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' })
    } else {
      return 1
    }
  })
}
