import React, { useEffect, useState } from 'react'
import { Divider, Grid, Paper, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import {
  Area,
  CartesianGrid,
  Legend,
  Line,
  AreaChart as RechartsAreaChart,
  LineChart as RechartsLineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { formatBigAndSmallNumber, truncateSize } from '../../../utils/dataFormatting'

const SinglePercentChart = ({ data, zoomState, useLog, isVertical, height, ...handlers }) => {
  const theme = useTheme()
  const chartColors = theme.palette.chart
  const { handleMouseDown, handleMouseMove, handleMouseUp } = handlers
  const [Chart, Series] = data.length === 1 ? [RechartsAreaChart, Area] : [RechartsLineChart, Line]

  const init = [...Array(data.length)].map((u, i) => i)
  const [selected, setSelected] = useState(init)

  useEffect(() => {
    setSelected(init)
  }, [data])

  const handleLocalMouseDown = (e) => {
    return e === null ? false : handleMouseDown(e)
  }

  const handleLocalMouseUp = (e) => {
    return e === null ? false : handleMouseUp(e)
  }

  const handleOnClick = (e, idx, c) => {
    if (c.shiftKey) {
      setSelected((_prev) => init.filter((i) => i === idx))
    } else {
      if (selected.includes(idx)) {
        setSelected((prev) => prev.filter((i) => i !== idx))
      } else {
        setSelected((prev) => [...prev, idx])
      }
    }
  }

  const CustomTooltip = ({ payload = [], active, label }) => {
    if (active && payload) {
      const sortedItems = [...payload].sort((a, b) => b.value - a.value)
      const { hasValue, noValue } = sortedItems.reduce(
        (result, item) => {
          if (item.value) {
            result.hasValue.push(item)
          } else {
            result.noValue.push(item)
          }
          return result
        },
        { hasValue: [], noValue: [] },
      )

      const Item = ({ color, name, value }) => (
        <div key={name} className="mb-2" style={{ color }}>
          {truncateSize(name)}: {value}
        </div>
      )

      return (
        <Paper sx={{ px: 2, py: 1 }}>
          <Typography gutterBottom>
            <b>{label}</b>
          </Typography>
          {hasValue.map(Item)}
          {noValue.length > 0 && (
            <div>
              <Divider />
              {noValue.map(Item)}
            </div>
          )}
        </Paper>
      )
    }
    return null
  }

  if (!Array.isArray(data)) {
    return null
  }

  return (
    <div style={{ width: '100%', userSelect: 'none' }} data-test-hook="percent-chart">
      <ResponsiveContainer height={height}>
        <Chart
          layout={isVertical ? 'vertical' : 'horizontal'}
          onMouseDown={isVertical ? undefined : handleLocalMouseDown}
          onMouseMove={isVertical ? undefined : handleMouseMove}
          onMouseUp={isVertical ? undefined : handleLocalMouseUp}
          activeDot={{ r: 1 }}
          height={500}
          margin={{ top: 5, right: 20, bottom: 0, left: 0 }}
        >
          {data.length > 1 && (
            <Legend
              wrapperStyle={{ fontSize: '0.9rem', paddingBottom: '10px' }}
              verticalAlign="top"
              onClick={handleOnClick}
              payload={data
                .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
                .map((item, idx) => ({
                  type: selected.includes(idx) ? 'square' : 'line',
                  value: item.name || (isVertical ? 'count' : 'percent'),
                  color: chartColors[idx % chartColors.length],
                }))}
            />
          )}
          <CartesianGrid strokeDasharray="4 4" />
          {data
            .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
            .map((s, index) => (
              <Series
                isAnimationActive={false}
                dot={false}
                type="linear"
                stroke={chartColors[index % chartColors.length]}
                strokeWidth={1.5}
                dataKey={isVertical ? 'count' : 'percent'}
                name={s.name || ''}
                key={s.name || ''}
                data={
                  selected.includes(index)
                    ? useLog
                      ? s.data
                      : s.data?.filter((point) => point.percent < 96)
                    : undefined
                }
              />
            ))}

          <XAxis
            allowDataOverflow
            domain={[zoomState.left, zoomState.right]}
            tick={{
              fontSize: 12,
              fill: theme.palette.text.primary,
            }}
            tickFormatter={(ticker) => formatBigAndSmallNumber(ticker)}
            type="number"
            dataKey="count"
            scale={useLog ? 'log' : 'auto'}
            allowDuplicatedCategory={false}
          />
          <YAxis
            label={{
              value: 'Percent',
              angle: -90,
              position: 'insideLeft',
              dx: 10,
              fill: theme.palette.text.primary,
            }}
            tick={{ fontSize: 12, fill: theme.palette.text.primary }}
            reversed={isVertical ? true : false}
            dataKey="percent"
            allowDuplicatedCategory={false}
          />

          {!isVertical && zoomState.refAreaLeft && zoomState.refAreaRight && (
            <ReferenceArea
              x1={zoomState.refAreaLeft}
              x2={zoomState.refAreaRight}
              strokeOpacity={0.3}
            />
          )}
          <Tooltip content={CustomTooltip} />
        </Chart>
      </ResponsiveContainer>
    </div>
  )
}

export default function PercentChart(props) {
  const { data, layout } = props

  return layout === 'multiple' ? (
    <Grid container spacing={5}>
      {data.length &&
        data
          .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
          .map((series) => (
            <Grid item xs={12} md={6} xl={4} key={series.name}>
              <Typography gutterBottom sx={{ fontWeight: 'bold', pl: '60px', textAlign: 'center' }}>
                {series.name}
              </Typography>
              <SinglePercentChart height={300} field={series.name} {...props} data={[series]} />
            </Grid>
          ))}
    </Grid>
  ) : (
    <SinglePercentChart {...props} field={undefined} height={500} />
  )
}
PercentChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  handleMouseDown: PropTypes.func.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  handleMouseUp: PropTypes.func.isRequired,
}
