import { Box, styled, useTheme } from '@mui/material'
import { getContext } from '../utils/dataFormatting'

/**
 * Displays the Team, Property, and Environment context to match what the user would see in the Edgio console.
 *
 * Note: There are a lot of precise tweaks to styles here. They exist to match the header styles
 * from the Edgio console.
 */
export default function Breadcrumbs() {
  const context = getContext()
  const theme = useTheme()

  let teamLabel

  if (context == null) {
    return null
  } else if (context.admin) {
    teamLabel = null
  } else if (context.team.personal) {
    teamLabel = 'Private Space'
  } else if (context.team) {
    teamLabel = (
      <span>
        Organization <small>({context.team.tier})</small>
      </span>
    )
  }

  return (
    <Box
      display="flex"
      sx={{
        alignSelf: 'stretch',
        paddingLeft: theme.spacing(3),
        borderLeft: `1px solid ${theme.palette.bodyDivider}`,
      }}
    >
      <Box display="flex" alignItems="center">
        <ContextItem label={teamLabel} value={context.team ?? { name: 'Administrator' }} />
        {context.site && (
          <>
            <ContextDivider />
            <ContextItem label="Property" value={context.site} />
          </>
        )}
        {context.environment && (
          <>
            <ContextDivider />
            <ContextItem label="Environment" value={context.environment} />
          </>
        )}
      </Box>
    </Box>
  )
}

const ContextDivider = styled('div')(() => ({
  height: 40,
  margin: '0 20px',
  transform: 'rotate(22deg)',
  borderLeft: '1px solid #00A2E2',
}))

const ContextItem = ({ label, value }) => {
  return (
    <div style={{ marginTop: -4 }}>
      <div>
        {value.href ? (
          <a
            href={value.href}
            target="_blank"
            rel="noreferrer noopener"
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            {value.name}
          </a>
        ) : (
          <span>{value.name}</span>
        )}
      </div>
      <div
        style={{
          opacity: 0.6,
          fontWeight: 100,
          textTransform: 'uppercase',
          fontSize: '12px',
          marginTop: -2,
        }}
      >
        {label}
      </div>
    </div>
  )
}
