import React, { useCallback, useEffect, useMemo } from 'react'
import { IgnoreKeys } from 'react-hotkeys'
import {
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { getControl } from '../../../utils/useControls'
import FieldAutocomplete from '../../FieldAutocomplete'
import { operators } from './FilterOption'

const preDefinedFilters = [
  { name: 'Empty String', value: 'regex:?*|null' },
  { name: 'Any String', value: 'regex:.+' },
]

const FilterForm = React.forwardRef((props, forwardedRef) => {
  const {
    filteredOptions,
    existingFilter,
    filterName,
    setFilterName,
    filterValue,
    setFilterValue,
    fieldType,
    setFieldType,
    saveFilter,
    selectFilter,
    isSubmitted,
    operatorId,
    setOperatorId,
  } = props

  const isOperatorAvailable = (operator, fieldType) => {
    const fieldFilters = getControl('filters').filter((filter) => filter.field === filterName) || []

    if (fieldFilters.length === 0) {
      return (
        operator?.applicableTypes === undefined || operator?.applicableTypes.includes(fieldType)
      )
    }
    if (fieldFilters.length === 2) {
      return existingFilter.operatorId === operator.id && existingFilter.field !== ''
    }
    const isEditing =
      fieldFilters.length === 1 &&
      fieldFilters.some(
        (filter) =>
          _.isEqual(filter, existingFilter) &&
          (filter.operatorId === operator.id ||
            operator?.applicableTypes === undefined ||
            operator?.applicableTypes.includes(fieldType)),
      )
    if (isEditing) {
      return true
    }

    const existingOperator = fieldFilters[0]?.operatorId
    return (
      [operators.le.id, operators.ge.id].includes(existingOperator) &&
      [operators.le.id, operators.ge.id].includes(operator.id) &&
      existingOperator !== operator.id
    )
  }

  const isSaveDisabled = useMemo(() => {
    return (
      existingFilter &&
      filterName === existingFilter.field &&
      filterValue === existingFilter.value &&
      operatorId === existingFilter.operatorId
    )
  }, [existingFilter, filterName, filterValue, operatorId])

  const handleOnChange = (e) => {
    if (e.target.value) {
      setFilterValue(buildFilterValue(e.target.value))
    }
  }

  const handleDropdownSelect = (e, option) => {
    if (option && option.value) {
      setFilterValue(buildFilterValue(option.value))
    }
  }

  const buildFilterValue = (value) => {
    return ['an', 'lc'].includes(filterName)
      ? value.toUpperCase()
      : filterName === 'pop'
      ? value.toLowerCase()
      : value
  }

  const handleKeyDown = useCallback(
    (e) => {
      if ('Enter' === e.key) {
        saveFilter(e)
      }
    },
    [saveFilter],
  )

  useEffect(() => {
    const filter = filteredOptions.find((element) => {
      return element.value === filterName
    })
    setFieldType(filter ? filter.type : null)
  }, [filterName])

  const availableOperators = useMemo(() => {
    if (!filterName) {
      return Object.values(operators)
    }
    const availableOperators = Object.values(operators).filter((operator) =>
      isOperatorAvailable(operator, fieldType),
    )

    if (availableOperators.every((operator) => operator.id !== operatorId)) {
      setOperatorId(availableOperators[0]?.id || null)
    }
    return availableOperators
  }, [filterName, fieldType])

  return (
    <IgnoreKeys>
      <form onSubmit={saveFilter} data-test-hook="filter-form">
        <div className="form-row">
          <div className="col-12">
            <FieldAutocomplete
              disableClearable
              onChange={(_e, value) => {
                setFilterName(value.id)
                setOperatorId(operators.eq.id)
              }}
              value={filterName}
              fields={filteredOptions}
              inputProps={{
                fullWidth: true,
                inputRef: forwardedRef,
              }}
              helperText={!operatorId && <span>Filter unavailable</span>}
            />
            {!operatorId && <span>Filter unavailable</span>}
            {isSubmitted && !filterName.length && (
              <div className="help-text text-danger small mt-3">Required field</div>
            )}
          </div>
        </div>
        <div className="form-row mt-3">
          <Box sx={{ display: 'flex', my: 1, alignItems: 'flex-start' }}>
            <Select
              value={operatorId}
              inputProps={{ IconComponent: () => null }}
              onChange={(_e) => {
                setOperatorId(_e.target.value)
              }}
              style={{ width: 44, minWidth: 0, height: 40 }}
              MenuProps={{ MenuListProps: { disablePadding: true } }}
              variant="outlined"
              size="small"
              error={!operatorId}
              helperText={!operatorId && <span>No operators available</span>}
            >
              {availableOperators.map((operator, idx) => (
                <MenuItem value={operator.id} key={idx}>
                  <Tooltip
                    title={operator.hover}
                    placement="right"
                    enterDelay={2000}
                    enterNextDelay={2000}
                  >
                    <Typography variant="h5">{operator.symbol}</Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
            <Autocomplete
              freeSolo
              disableClearable
              options={preDefinedFilters}
              defaultValue={{ name: filterValue, value: filterValue }}
              getOptionLabel={(option) => option.value}
              renderOption={(props, option) => <li {...props}>{option.name}</li>}
              onChange={(e, option) => handleDropdownSelect(e, option)}
              size="small"
              sx={{ ml: 1, flex: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="filterValue"
                  data-test-hook="filter-value"
                  placeholder="value or regex: (regular expression)"
                  onChange={handleOnChange}
                  onKeyDown={handleKeyDown}
                  value={filterValue}
                  error={(isSubmitted && filterValue && !filterValue.length) || !operatorId}
                  helperText={
                    isSubmitted && filterValue && !filterValue.length && <span>Required field</span>
                  }
                />
              )}
            />
          </Box>
        </div>
        <div className="clearfix" />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="outlined" onClick={() => selectFilter(false)} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" disabled={isSaveDisabled} onClick={saveFilter}>
            Save
          </Button>
        </Box>
      </form>
    </IgnoreKeys>
  )
})

FilterForm.displayName = 'FilterForm'

FilterForm.propTypes = {
  filteredOptions: PropTypes.array.isRequired,
  existingFilter: PropTypes.object.isRequired,
  filterName: PropTypes.string.isRequired,
  setFilterName: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  fieldType: PropTypes.string,
  setFieldType: PropTypes.func,
  saveFilter: PropTypes.func.isRequired,
  selectFilter: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  operatorId: PropTypes.string.isRequired,
  setOperatorId: PropTypes.func.isRequired,
  datasource: PropTypes.string.isRequired,
  selectedField: PropTypes.object,
}

export default FilterForm
