import React from 'react'
import AddCircle from '@mui/icons-material/AddCircle'
import Close from '@mui/icons-material/Close'
import Edit from '@mui/icons-material/Edit'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import { Box, Button, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import { removeFilter } from '../../../utils/useControls'
import { operators } from './FilterOption'

const FilterButton = ({ index, filterData, selectFilter, toggleFilter, selectedField }) => {
  const { name, value, active, operatorId } = filterData

  const displayValue = () => {
    const style = {
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      display: 'inline-block',
      padding: '0 6px 4px',
    }
    const textStyle = {
      textOverflow: 'ellipsis',
      maxWidth: 160,
      display: 'block',
      overflow: 'hidden',
      textAlign: 'left',
    }

    return (
      <React.Fragment>
        <span style={textStyle}>{selectedField?.label || name}</span>
        <span style={style}>
          {Object.values(operators).find((operator) => operator.id === operatorId)?.symbol}
        </span>
        <span style={textStyle}>{value}</span>
      </React.Fragment>
    )
  }

  return (
    <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
      <Button
        onClick={() => toggleFilter(index)}
        startIcon={active ? <AddCircle /> : <RemoveCircle />}
        variant="outlined"
        color={active ? 'primary' : 'inherit'}
        sx={{
          flex: 1,
          textTransform: 'none',
          justifyContent: 'flex-start',
          opacity: active ? 1 : 0.4,
        }}
      >
        {displayValue()}
      </Button>
      <IconButton onClick={() => selectFilter(index)} size="small" sx={{ ml: 1 }}>
        <Edit />
      </IconButton>
      <IconButton onClick={() => removeFilter(filterData)} size="small">
        <Close />
      </IconButton>
    </Box>
  )
}

FilterButton.propTypes = {
  index: PropTypes.number.isRequired,
  filterData: PropTypes.shape({
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    active: PropTypes.bool.isRequired,
    operatorId: PropTypes.string.isRequired,
  }).isRequired,
  selectFilter: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  selectedField: PropTypes.object,
}

export default FilterButton
