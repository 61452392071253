import React, { useEffect, useMemo, useState } from 'react'
import { IgnoreKeys } from 'react-hotkeys'
import Close from '@mui/icons-material/Close'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

const initTmz = 'Etc/UTC'

export const setTmz = (v) => {
  localStorage.setItem('tmz', v)
}

export const getTmz = () => {
  if (typeof window === 'undefined') return initTmz

  const tmz = localStorage.getItem('tmz')
  if (!tmz) {
    setTmz(initTmz)
    return initTmz
  }
  return tmz
}

export const TmzSetting = ({ isOpen, toggle }) => {
  const [tmzList, setTmzList] = useState([])
  const [currentTmz, setCurrentTmz] = useState(getTmz())

  const updateSelectedTmz = (_event, { value }) => {
    setCurrentTmz(value)
  }

  const updateTimeZone = (_e) => {
    setTmz(currentTmz)
    toggle()
  }

  const onToggle = () => {
    setCurrentTmz(getTmz())
    toggle()
  }

  useEffect(() => {
    const list = moment.tz.names()
    const offsetTmz = list.map((el) => ({
      name: '(GMT' + moment.tz(el).format('Z') + ') ' + el,
      value: el,
    }))
    setTmzList(offsetTmz)
  }, [])

  const currentTimeZoneValue = useMemo(
    () => tmzList.find((tmz) => tmz.value === currentTmz),
    [currentTmz, tmzList],
  )

  return (
    <IgnoreKeys>
      <Dialog open={isOpen} onClose={onToggle}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          Time Zone
          <Tooltip title="Close">
            <IconButton onClick={toggle} size="small">
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>Display all dates and times in:</Typography>
          <Autocomplete
            selectOnFocus
            id="time-zone-select"
            value={currentTimeZoneValue}
            onChange={updateSelectedTmz}
            getOptionLabel={(option) => option.name}
            options={tmzList}
            sx={{ width: 400, paddingTop: '10px' }}
            renderInput={(params) => <TextField {...params} label="Select Time Zone" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onToggle}>Cancel</Button>
          <Button color="primary" onClick={updateTimeZone} variant="contained">
            Update Time Zone
          </Button>
        </DialogActions>
      </Dialog>
    </IgnoreKeys>
  )
}

TmzSetting.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}
