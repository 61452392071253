import React, { useCallback, useContext, useMemo } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { Box, Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import AppContext from '../../AppContext'
import { setControls, useControls } from '../../utils/useControls'

const Intervals = () => {
  const { dataSource, interval, endTime } = useControls('dataSource', 'interval', 'endTime')

  const { dataSources } = useContext(AppContext)

  const maxInterval = useMemo(() => {
    return dataSources.find(({ value }) => value === dataSource)?.maxInterval
  }, [dataSource, dataSources])

  const minInterval = useMemo(() => {
    return dataSources.find(({ value }) => value === dataSource)?.minInterval
  }, [dataSource, dataSources])

  const setInterval = useCallback(
    (_event, value) => {
      if (value === null) return

      const end = Math.min(endTime, Math.floor(Date.now() / 1000))

      setControls({
        interval: value,
        endTime: end,
        startTime: end - value,
        resolution: 'auto',
      })
    },
    [endTime],
  )

  const intervals = {
    '5m': 60 * 5,
    '1h': 60 * 60,
    '6h': 60 * 60 * 6,
    '1d': 60 * 60 * 24,
    '7d': 60 * 60 * 24 * 7,
    ...((dataSource === 'rate_limiting' ||
      dataSource === 'waf' ||
      dataSource.startsWith('console/') ||
      maxInterval === '30d') && {
      '30d': 60 * 60 * 24 * 30,
    }),
    ...(dataSource.startsWith('console/') && {
      '1y': 60 * 60 * 24 * 365,
    }),
    ...(dataSource.startsWith('console/') && {
      '10y': 60 * 60 * 24 * 365 * 10,
    }),
  }

  if (maxInterval) {
    Object.keys(intervals).forEach((key) => {
      if (intervals[key] > intervals[maxInterval]) {
        delete intervals[key]
      }
    })
  }

  if (minInterval) {
    Object.keys(intervals).forEach((key) => {
      if (intervals[key] < intervals[minInterval]) {
        delete intervals[key]
      }
    })
  }

  const cycleIntervals = () => {
    const intervalValues = Object.values(intervals)
    const currentIndex = intervalValues.findIndex((int) => int === interval)
    const nextIndex = (currentIndex + 1) % intervalValues.length
    const nextInterval = intervalValues[nextIndex]

    setInterval(nextInterval)
  }

  const keyMap = {
    INTERVAL: { name: 'Change interval duration', sequence: 'i' },
  }

  const handlers = {
    INTERVAL: cycleIntervals,
  }

  return (
    <Box sx={{ my: 3 }}>
      <Typography sx={{ mb: 1 }}>
        <b>Time Period</b>
      </Typography>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges={true}>
        <ToggleButtonGroup exclusive value={interval} onChange={setInterval} fullWidth>
          {Object.entries(intervals).map(([label, value]) => (
            <ToggleButton key={label} value={value} size="small">
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </GlobalHotKeys>
    </Box>
  )
}

export default Intervals
