import React from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Timer from '@mui/icons-material/Timer'
import { Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { setControls, useControls } from '../../utils/useControls'

const Navigate = () => {
  const { endTime, interval } = useControls('endTime', 'interval')

  const now = Math.floor(Date.now() / 1000)
  const days30 = 60 * 60 * 24 * 30
  const halfInterval = interval / 2

  const navBack = () => {
    const newStart = endTime - interval - halfInterval
    const atLimit = newStart < now - days30
    setControls({
      startTime: atLimit ? now - days30 : newStart,
      endTime: atLimit ? now - days30 + interval : endTime - halfInterval,
      nowMode: false,
    })
  }
  const navForward = () => {
    const newEnd = endTime + halfInterval
    const atLimit = newEnd > now
    setControls({
      startTime: atLimit ? now - interval : newEnd - interval,
      endTime: atLimit ? now : newEnd,
      nowMode: false,
    })
  }
  const navNow = () => {
    setControls({
      startTime: now - interval,
      endTime: now,
      isNowQuery: true,
    })
  }

  const keyMap = {
    NAVLEFT: { name: 'Navigate back in time', sequence: 'ArrowLeft' },
    NAVRIGHT: { name: 'Navigate forward in time', sequence: 'ArrowRight' },
  }

  const handlers = {
    NAVLEFT: navBack,
    NAVRIGHT: navForward,
  }

  return (
    <Box sx={{ display: 'flex', mt: 1, mb: 4 }}>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges={true}>
        <ToggleButtonGroup sx={{ mr: 1 }}>
          <ToggleButton onClick={navBack} size="small" value="back">
            <ChevronLeft sx={{ height: 20 }} />
            Back
          </ToggleButton>
          <ToggleButton onClick={navForward} size="small" value="forward">
            Forward
            <ChevronRight sx={{ height: 20 }} />
          </ToggleButton>
        </ToggleButtonGroup>
        <Button onClick={navNow} variant="contained" size="small" startIcon={<Timer />}>
          Now
        </Button>
      </GlobalHotKeys>
    </Box>
  )
}

export default Navigate
