import { memo } from 'react'

const Linkify = memo(({ value }) => {
  if (typeof value === 'string' && value.match(/^https?:\/\//)) {
    return (
      <a href={value} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
        {value}
      </a>
    )
  }

  return value
})

Linkify.displayName = 'Linkify'

export default Linkify
