import React, { useEffect, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import Apps from '@mui/icons-material/Apps'
import Help from '@mui/icons-material/Help'
import LineAxis from '@mui/icons-material/LineAxis'
import {
  Box,
  Card,
  CardContent,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import PropTypes from 'prop-types'
import { setControls, useControls } from '../../utils/useControls'
import CardHeader from '../CardHeader'
import RefreshButton from '../GlobalControls/RefreshButton'
import LoadingIndicator from '../LoadingIndicator'
import Analytics, { chartTypes, isChartAvailable } from './Analytics'
import DataTable from './Charts/DataTable'
import LineChart from './Charts/LineChart'
import PercentChart from './Charts/PercentChart'
import DownloadDataLink from './DownloadDataLink'

const initialZoom = {
  refAreaLeft: '',
  refAreaRight: '',
  left: 'dataMin',
  right: 'dataMax',
  active: false,
}

const Histogram = ({ data, fields, isLoading, fetchFunc }) => {
  const {
    dataSource,
    avgMode,
    logMode,
    hMode,
    histogramType,
    resolution,
    interval,
    selectedTopField,
    layout,
    topField1,
    topField2,
    startTime,
    endTime,
  } = useControls(
    'dataSource',
    'avgMode',
    'logMode',
    'hMode',
    'histogramType',
    'resolution',
    'interval',
    'selectedTopField',
    'layout',
    'topField1',
    'topField2',
    'startTime',
    'endTime',
  )

  const [originalTimePeriod, setOriginalTimePeriod] = useState(null)

  const [zoomState, setZoomState] = useState(initialZoom)

  // If the user manually changes the time period, reset the zoom
  useEffect(() => {
    if (!zoomState.active) return
    if (zoomState.left / 1000 !== startTime || zoomState.right / 1000 !== endTime) {
      setZoomState(initialZoom)
    }
  }, [startTime, endTime, zoomState])

  const Label = styled(Typography)(({ theme }) => ({
    opacity: 0.6,
    ...theme.typography.body2,
  }))

  const toggleAvg = () => setControls({ avgMode: !avgMode })

  const resolutions = ['Second', 'Minute', 'Hour', 'Day']
  dataSource !== 'analytics' && resolutions.unshift('Auto')

  const setResolution = (res) => {
    setControls({
      resolution: res,
    })
  }

  const setChartType = (value) => {
    setControls({
      histogramType: value,
      ...(value.includes('historical_') && { selectedTopField: 0 }),
    })
  }

  const selectTopField = (fieldIndex) => {
    setControls({
      selectedTopField: fieldIndex,
    })
  }
  useEffect(() => {
    handleChartChange(
      null,
      !histogramType || histogramType === 'histogram' ? 'timeline' : 'analytics',
    )
  }, [dataSource, histogramType])

  useEffect(() => {
    if (selectedTopField === 0) {
      setControls({ layout: 'single' })
    }
  }, [selectedTopField])

  const cycleSources = () => {
    selectTopField((selectedTopField + 1) % 3)
  }

  const cycleResolutions = () => {
    const currentIndex = resolutions.findIndex((res) => res.toLowerCase() === resolution)
    const findNextIndex = (index) => {
      const nextIndex = (index + 1) % resolutions.length
      if (disableResolution(resolutions[nextIndex])) {
        return findNextIndex(nextIndex)
      } else {
        return nextIndex
      }
    }
    const nextIndex = findNextIndex(currentIndex)
    const nextRes = resolutions[nextIndex].toLowerCase()
    setResolution(nextRes)
  }

  const disableResolution = (value) => {
    const disableValues = []
    if (dataSource.toString().startsWith('bassfish') && value === 'second') {
      disableValues.push('second')
    }
    // Disable seconds at 6 hours and above
    if (interval >= 60 * 60 * 6) {
      disableValues.push('second')
    }
    // Disable minutes at 7 days and above
    if (interval >= 60 * 60 * 24 * 7) {
      disableValues.push('minute')
    }
    return disableValues.includes(value.toLowerCase())
  }

  const handleZoom = () => {
    let { refAreaLeft, refAreaRight, active } = zoomState
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setZoomState(({ left, right, active }) => ({
        refAreaLeft: '',
        refAreaRight: '',
        left,
        right,
        active,
      }))
      return
    }

    if (refAreaLeft > refAreaRight) {
      // Flip value assignments
      ;[refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft]
    }

    setZoomState({
      refAreaLeft: '',
      refAreaRight: '',
      left: refAreaLeft,
      right: refAreaRight,
      active: true,
    })

    // Apply the zoom boundaries as the start and end dates
    setControls({ startTime: refAreaLeft / 1000, endTime: refAreaRight / 1000 })

    // Save the original time period so we can reset the zoom
    if (!active) {
      setOriginalTimePeriod({ startTime, endTime })
    }
  }

  const setLeftZoomRef = (e) => {
    if (e?.activeLabel) {
      setZoomState((state) => ({ ...state, refAreaLeft: e.activeLabel }))
    }
  }

  const setRightZoomRef = (e) => {
    if (e?.activeLabel) {
      setZoomState((state) => {
        if (state.refAreaLeft) return { ...state, refAreaRight: e.activeLabel }
        return state
      })
    }
  }

  const [source1, source2] = [topField1, topField2].map(
    (topField) => fields.find((field) => field.value === topField)?.label,
  )

  const resetZoom = () => {
    setZoomState(initialZoom)
    setControls(originalTimePeriod)
    setOriginalTimePeriod(null)
  }

  const [analyticChartType, setAnalyticsChart] = React.useState()
  const [chart, setChart] = React.useState('timeline')
  const handleChartChange = (_e, val) => {
    if (!val || val === 'timeline') {
      setChartType('histogram')
    } else {
      if (!analyticChartType) {
        const defaultChart = chartTypes.find((item) => isChartAvailable(item, dataSource)).value
        setAnalyticsChart(defaultChart)
        setChartType(defaultChart)
      } else {
        if (isChartAvailable(analyticChartType, dataSource)) {
          setChartType(analyticChartType)
        } else {
          setChartType(chartTypes.find((item) => isChartAvailable(item, dataSource)).value)
        }
      }
    }
    setChart(val)
  }
  const handlers = {
    TIMES: cycleSources,
    TIMER: cycleResolutions,
  }

  const showAnalyticsTab = !dataSource.startsWith('console/')

  const keyMap = {
    TCHART: { name: 'Show the chart type dropdown options', sequence: 't' },
    TIMES: { name: 'Change time chart source', sequence: 's' },
    TIMER: { name: 'Change time chart resolution', sequence: 'r' },
  }
  if (data && data.length) {
    const isSeconds = Date.now() > data[0].time * 1000
    if (isSeconds) {
      data = data.map((item) => (item.time *= 1000))
    }
  }
  return (
    <Card elevation={3} sx={{ mb: 3, position: 'relative' }}>
      {showAnalyticsTab && (
        <Box>
          <Tabs value={chart} onChange={handleChartChange} defaultValue={'timeline'}>
            <Tab label="Timeline" value={'timeline'} />
            {(!fields.some((i) => i.isAnalyzed === true) && (
              <Tooltip title="The datasource has no analyzed fields">
                <div>
                  <Tab label="Analytics" value={'analytics'} disabled={true} />
                </div>
              </Tooltip>
            )) || <Tab label="Analytics" value={'analytics'} />}
          </Tabs>
        </Box>
      )}
      <CardHeader>
        <Box position="absolute" top={12} right={16}>
          <RefreshButton fetchFunc={fetchFunc} />
          <DownloadDataLink data={data} />
        </Box>
        <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges={true}>
          <Box display="flex" gap={2} flexWrap="wrap" alignItems="flex-start">
            <Box position="absolute" top={12} right={16}>
              <RefreshButton fetchFunc={fetchFunc} />
              <DownloadDataLink data={data} />
            </Box>
            {chart === 'timeline' ? (
              <Box display="flex" flexDirection="column">
                <Label>Y-Axis</Label>
                <ToggleButtonGroup
                  exclusive
                  value={avgMode}
                  color="primary"
                  style={{ marginTop: '5px' }}
                >
                  <ToggleButton size="small" onClick={() => toggleAvg(false)} value={false}>
                    Count
                  </ToggleButton>

                  <ToggleButton size="small" onClick={() => toggleAvg(true)} value={true}>
                    Events/sec
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            ) : (
              <Analytics
                isLoading={isLoading}
                fields={fields}
                setAnalyticsChart={setAnalyticsChart}
                histogramType={histogramType}
                zoomState={zoomState}
                resetZoom={resetZoom}
                disableResolution={disableResolution}
                handlers={handlers}
                data={data}
              />
            )}
            {histogramType !== 'stats' && (
              <Box display="flex" flexDirection="column">
                <Label gutterBottom>Resolution</Label>
                <ToggleButtonGroup exclusive value={resolution} color="primary">
                  {resolutions
                    .filter((r) => !disableResolution(r))
                    .map((displayValue) => {
                      const value = displayValue.toLowerCase()
                      return (
                        <ToggleButton
                          size="small"
                          key={displayValue}
                          value={value}
                          disabled={disableResolution(value)}
                          onClick={() => setResolution(value)}
                        >
                          {displayValue}
                        </ToggleButton>
                      )
                    })}
                </ToggleButtonGroup>
              </Box>
            )}
            {histogramType && !histogramType.includes('historical_') && source1 && (
              <Box display="flex" flexDirection="column">
                <Label gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  Source
                  <Tooltip
                    title={
                      <div>
                        <p style={{ marginTop: 0 }}>
                          The source control allows you to display a separate series or chart for
                          each source value.
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          The available sources can be changed using the select lists under the pie
                          charts in the <b>Top Results</b> section below.
                        </p>
                      </div>
                    }
                  >
                    <Help sx={{ height: 16, margin: 0 }} />
                  </Tooltip>
                </Label>
                <Box display="flex" gap={1} alignItems="center">
                  <ToggleButtonGroup value={selectedTopField} exclusive color="primary">
                    {['All', source1, source2].map((source, index) => (
                      <ToggleButton
                        size="small"
                        key={index}
                        value={index}
                        onClick={() => selectTopField(index)}
                      >
                        {source}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Box>
              </Box>
            )}
            {histogramType !== 'stats' && selectedTopField > 0 && (
              <Box display="flex" flexDirection="column">
                <Label gutterBottom>Layout</Label>
                <ToggleButtonGroup exclusive value={layout} color="primary">
                  <ToggleButton
                    value="single"
                    sx={{ px: 0.75 }}
                    onClick={() => setControls({ layout: 'single' })}
                  >
                    <Tooltip title="Show all series on a single chart">
                      <LineAxis />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton
                    value="multiple"
                    sx={{ px: 0.75 }}
                    onClick={() => setControls({ layout: 'multiple' })}
                  >
                    <Tooltip title="Show each series on a separate chart">
                      <Apps />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
            {zoomState.active && (
              <>
                <ToggleButton
                  value="zoom"
                  className="btn btn-secondary ml-auto"
                  onClick={resetZoom}
                  style={{ alignSelf: 'flex-end' }}
                >
                  Reset Zoom
                </ToggleButton>
              </>
            )}
          </Box>
        </GlobalHotKeys>
      </CardHeader>
      <CardContent sx={{ position: 'relative', minHeight: 300 }}>
        <LoadingIndicator loading={isLoading} />
        {data.length === 0 && (
          <div
            style={{
              position: 'absolute',
              color: '#ccc',
              paddingLeft: '85px',
              paddingTop: '5px',
            }}
          >
            Could not retreive analytics. Please apply a filter or decrease the time period.
          </div>
        )}
        {chart === 'analytics' && histogramType === 'stats' ? (
          <DataTable data={data} />
        ) : (
          <>
            {chart === 'analytics' && histogramType === 'percentiles' ? (
              <PercentChart
                data={data}
                layout={layout}
                useLog={logMode}
                isVertical={hMode}
                handleMouseDown={setLeftZoomRef}
                handleMouseMove={setRightZoomRef}
                handleMouseUp={handleZoom}
                zoomState={zoomState}
              />
            ) : (
              <LineChart
                data={data}
                layout={layout}
                avgMode={avgMode}
                handleMouseDown={setLeftZoomRef}
                handleMouseMove={setRightZoomRef}
                handleMouseUp={handleZoom}
                zoomState={zoomState}
              />
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}

Histogram.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  fields: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchFunc: PropTypes.func.isRequired,
}

export default Histogram
