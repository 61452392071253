import { useEffect, useState } from 'react'
import { formatControlsData, parseFieldSelection } from './dataFormatting'
import { fetchMultiple, getAppData } from './fetch'
import { useControls } from './useControls'

const fieldsInfo = [
  { id: 'x_ec_config_version', name: 'Customer config version' },
  { id: 'status_code', name: 'HTTP Status Code' },
  { id: 'apparent_cache_log_status', name: 'Cache Status' },
]

export const SEPERATOR = '-'

export const useEcData = () => {
  const {
    filters,
    dataSource,
    histogramType,
    startTime,
    endTime,
    resolution,
    prefix,
    quantiles,
    vs,
  } = useControls(
    'filters',
    'dataSource',
    'histogramType',
    'startTime',
    'endTime',
    'resolution',
    'prefix',
    'quantiles',
    'vs',
  )

  const [histogramData, setHistogramData] = useState([])
  const [isHistogramLoading, setIsHistogramLoading] = useState(null)

  const fetchData = async () => {
    setIsHistogramLoading(true)

    try {
      const dataPromises = []
      const ks = parseFieldSelection(vs, SEPERATOR)

      fieldsInfo.map((item, i) => (fieldsInfo[i] = { ...item, keys: ks[i] }))

      fieldsInfo.slice(1).forEach((f, idx) => {
        fieldsInfo[0].keys.forEach((v, _index) => {
          dataPromises.push(fetchHistogram(f, v, idx))
        })
      })

      await fetchMultiple(dataPromises)
      setIsHistogramLoading(false)
    } catch (error) {
      console.log(`Failed to load time chart data. (${error.message})`)
    }
  }

  const fetchHistogram = async (field, version, idx) => {
    let sourceKeys = []
    const promises = []

    const getHistogram = (filter = {}) => {
      return getAppData(dataSource, histogramType, {
        filters: [
          ...filters,
          filter,
          {
            field: fieldsInfo[0].id,
            value: version,
            active: true,
          },
        ],

        resolution,
        startTime,
        endTime,
        field: field.id,
        prefix,
        quantiles,
      })
    }

    if (field.keys) {
      field.keys.forEach((term) => {
        sourceKeys.push(term)
        promises.push(
          getHistogram({
            field: field.id,
            value: term,
            active: true,
          }),
        )
      })
    }

    try {
      const histogramResponse = await fetchMultiple(promises)
      const formattedData = formatControlsData(histogramResponse, sourceKeys, version)

      setHistogramData((prev) => {
        const raw = { ...prev[idx]?.results, ...formattedData }
        const filtered = {}
        fieldsInfo[0].keys.map((v) => (filtered[v] = raw[v]))

        prev[idx] = {
          ...field,
          results: filtered,
          link: histogramResponse[0]?.link,
        }
        return prev
      })
    } catch (error) {
      console.log(`Failed to load time chart data. (${error.message})`)
    }
  }

  useEffect(() => {
    if (vs) {
      fetchData()
    } else {
      setHistogramData([])
      setIsHistogramLoading(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vs])

  return {
    histogramData,
    isHistogramLoading,
  }
}

export const useFieldsData = () => {
  const { filters, dataSource, topN, startTime, endTime } = useControls(
    'filters',
    'dataSource',
    'topN',
    'startTime',
    'endTime',
  )

  const [fieldsData, setFieldsData] = useState(fieldsInfo)
  const [isFieldsLoading, setIsFieldsLoading] = useState(true)

  const fetchFieldsResults = async () => {
    setIsFieldsLoading(true)
    try {
      const topPromises = []
      fieldsInfo.forEach((item) => {
        topPromises.push(
          getAppData(dataSource, 'top', {
            filters,
            topN,
            startTime,
            endTime,
            field: item.id,
          }),
        )
      })
      const results = await fetchMultiple(topPromises)
      setFieldsData(results)
      setIsFieldsLoading(false)
    } catch (error) {
      console.log(`Failed to load top results. (${error.message})`)
    }
  }

  useEffect(() => {
    if (filters.length > 0) {
      fetchFieldsResults()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[0]?.value, startTime, endTime])

  return {
    fieldsInfo,
    fieldsData,
    isFieldsLoading,
  }
}
