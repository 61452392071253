import moment from 'moment'
import { operators } from '../components/GlobalControls/Filters/FilterOption'
import { teamIdFieldNameMap } from './fetch'

export const formatPermissions = (permissions) =>
  permissions.reduce(
    (result, { parent, name }) => {
      if (parent?.name === 'Edge Insights') {
        const property = name.replace(' Profile', '').toLowerCase()
        result[property] = true
      }
      return result
    },
    { view: false, create: false, update: false, delete: false },
  )

// Simplest way to convert `2000000` to `2,000,000`
export const formatNumber = (number) => {
  // return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
  return number.toLocaleString(undefined, { maximumFractionDigits: 4 })
}

// Returns a number of a maximum 5 digits, like `24,583` or `245.83M`
export const formatBigNumber = (number) => {
  if (typeof number === 'number') {
    // You can use _ to more clearly delineate large numeric values!
    if (number >= 1_000_000_000_000_000 || number <= -1_000_000_000_000_000) {
      return `${(number / 1_000_000_000_000_000).toFixed(2)}Q`
    }
    if (number >= 1_000_000_000_000 || number <= -1_000_000_000_000) {
      return `${(number / 1_000_000_000_000).toFixed(2)}T`
    }
    if (number >= 1_000_000_000 || number <= -1_000_000_000) {
      return `${(number / 1_000_000_000).toFixed(2)}B`
    }
    if (number >= 1_000_000 || number <= -1_000_000) {
      return `${(number / 1_000_000).toFixed(2)}M`
    }
    if (number >= 100_000 || number <= -100_000) {
      return `${(number / 1_000).toFixed(2)}K`
    }
    return formatNumber(number)
  }
}

export const formatBigAndSmallNumber = (number) => {
  if (typeof number === 'number') {
    if (number >= 1000) {
      return formatBigNumber(Math.round(number))
    } else if (number >= 1) {
      return +number.toFixed(2)
    } else {
      return +number.toPrecision(2)
    }
  }
}

// Gets rid of any properties on an object whose value is `undefined` or `null`.
// Used for stripping unused query params from fetches
export const filterUndefinedProperties = (object) =>
  Object.entries(object).reduce((result, [key, value]) => {
    if (![undefined, null].includes(value)) {
      if (key === 'filters') {
        Object.keys(value).forEach((valueKey) => {
          if (
            Object.values(operators)
              .map((operator) => operator.id)
              .includes(valueKey)
          ) {
            result[valueKey] = JSON.stringify(value[valueKey])
            delete value[valueKey]
          }
        })
      }
      result[key] = value
    }
    return result
  }, {})

// Filters are stored in app as `[{ field, value, active }]` but are sent in
// params (if active) as `{field:value}`
export const formatFiltersForFetch = (filters, dataSource) => {
  const getFilterValue = ({ value }) => {
    if (typeof value === 'string' && value?.includes('REGEX')) {
      value = value.replace('REGEX', 'regex')
    }
    return value
  }
  let reducedFilters = {}

  if (filters.length) {
    reducedFilters = filters.reduce((group, filter) => {
      const { operatorId } = filter
      group[operatorId] = group[operatorId] ?? {}
      if (filter.active) {
        group[operatorId][filter.field] = getFilterValue(filter)
      }
      return group
    }, {})
  }
  const context = getContext()
  const teamIdField = teamIdFieldNameMap[dataSource]
    ? teamIdFieldNameMap[dataSource]
    : teamIdFieldNameMap.default
  if (context?.team) {
    reducedFilters[teamIdField] = context.team.idsTenantId
  }
  return reducedFilters
}

export const formatParams = (params, dataSource) => {
  const {
    field,
    filters,
    endTime,
    startTime,
    topN,
    resolution,
    perPage,
    currentPage,
    prefix,
    quantiles,
    team_id,
    sources,
  } = params

  return filterUndefinedProperties(
    {
      field,
      filters: formatFiltersForFetch(filters, dataSource),
      end_time: endTime,
      start_time: startTime,
      size: topN,
      bin: resolution,
      bin_interval: resolution,
      per_page: perPage,
      page: currentPage,
      prefix,
      quantiles,
      team_id,
      sources,
    },
    dataSource,
  )
}

const flatBinsData = (data) => {
  // for historical analytics data, which has bins
  return data.map((i) => {
    let obj
    if (i.count) {
      const { date: _date, std_deviation_bounds: _std_deviation_bounds, ...rest } = i
      obj = { ...rest }
      obj.time = moment.utc(i.date).valueOf()
      obj.lower = i.std_deviation_bounds.lower
      obj.upper = i.std_deviation_bounds.upper
    } else {
      obj = { ...i }
      obj.time = moment.utc(obj.date).valueOf()
      delete obj.date
    }
    return obj
  })
}

const flatData = (data, key) => {
  // for percentiles single and multiple
  const filteredData = Object.entries(data).reduce((result, [k, v]) => {
    const parsed = parseInt(k)
    if (!isNaN(parsed)) {
      result.push({ percent: parsed, count: v === 0 ? 0.00001 : v })
    }
    return result
  }, [])

  filteredData.sort((a, b) => a.percent - b.percent)
  return {
    data: filteredData,
    ...(key && { name: key }),
  }
}

export const formatAnalyticData = (data, type, keys) => {
  if (type === 'stats') {
    if (keys.length > 0) {
      return data.reduce((result, { data: current }, index) => {
        const key = keys[index]
        result[key] = current.meta
        return result
      }, {})
    } else {
      return data[0].data.meta
    }
  } else {
    return data.reduce((result, { data: current }, index) => {
      if (keys.length > 0) {
        const key = keys[index]
        result.push(flatData(current.meta, key))
      } else {
        if (current.meta.bins) {
          result = flatBinsData(current.meta.bins)
        } else {
          result.push(flatData(current.meta))
        }
      }
      return result
    }, [])
  }
}

export const formatHistogramData = (data, source, keys) => {
  // If multiple sources are used to format data, a `series` will have a
  // `results` property, otherwise the data is returned as is
  if (source.startsWith('bassfish')) {
    return data[0].data.results
  }
  const dataAsObject = data.reduce((result, { data: series }, index) => {
    if (series.results) {
      const key = ' ' + (keys[index] ?? source)
      result[key] = {
        results: series.results,
      }
    } else {
      result = series
    }
    return result
  }, {})

  if (!dataAsObject.results) {
    console.time('Formatted histogram in')
    const groupedByTime = Object.entries(dataAsObject).reduce((result, [key, value]) => {
      value.results.forEach(({ count, time }) => {
        result[time] = { ...result[time], [key]: count }
      })
      return result
    }, {})
    const formattedData = Object.entries(groupedByTime).map(([time, entry]) => ({
      ...entry,
      time: parseInt(time, 10),
    }))
    console.timeEnd('Formatted histogram in')
    return formattedData
  } else {
    return data.results
  }
}

export const formatControlsData = (data, keys, version) => {
  const obj = { [version]: {} }
  keys.forEach((k, i) => {
    obj[version][k] = data[i].data.results
  })
  return obj
}

export const formatTopData = ({ results, total }) => {
  if (results) {
    const resultsCount = results.reduce((sum, item) => sum + item.count, 0)
    const restCount = total - resultsCount
    results.push({ term: 'Rest...', count: restCount })
    return { results: results, total }
  }
}

export const parseFieldSelection = (vs, seperator) => {
  if (vs) {
    const ks = vs.reduce((acc, cur) => {
      const [i, j] = cur.split(seperator)
      if (!acc[i]) {
        acc[i] = []
      }
      acc[i].push(j)
      return acc
    }, [])
    return ks
  }
  return vs
}

export const truncateSize = (name) => {
  const maxSize = 30
  if (name.length > maxSize) return name.substring(0, maxSize) + ' ... '

  return name
}

export const getRawContext = () => {
  const url = new URL(window.location.href)
  return url.searchParams.get('context')
}

export const getContext = () => {
  const context = getRawContext()

  if (context) {
    return JSON.parse(fromBase64(context))
  } else {
    return null
  }
}

function fromBase64(base64) {
  return Buffer.from(base64, 'base64').toString('utf8')
}
