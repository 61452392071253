import React from 'react'
import Refresh from '@mui/icons-material/Refresh'
import { Button, Card, CardContent, Typography } from '@mui/material'
import { logError } from '../airbrake'
import { getRawContext } from '../utils/dataFormatting'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, expand: false }
  }

  componentDidCatch(error, info) {
    logError(error, info)
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  handleRefresh = () => {
    const context = getRawContext()
    let newURL = window.location.pathname

    if (context) {
      newURL += `?context=${encodeURIComponent(context)}`
    }

    window.location.href = newURL
  }

  toggleExpand = () => {
    this.setState({ expand: !this.state.expand })
  }

  render() {
    const { expand } = this.state

    if (this.state.hasError) {
      return (
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography color="secondary" variant="h2" sx={{ fontWeight: 'thin' }}>
              Oops!
            </Typography>
            <Typography>Something went wrong. Please reload and try again.</Typography>

            {/* eslint-disable @next/next/no-html-link-for-pages */}
            <a href="/#" onClick={this.toggleExpand}>
              show {expand ? 'less' : 'more'}...
            </a>
            {expand && <pre>{this.state.error.stack}</pre>}
            <Button onClick={this.handleRefresh} endIcon={<Refresh />} variant="contained">
              Reload
            </Button>
          </CardContent>
        </Card>
      )
    }

    return this.props.children
  }
}
