import React, { useCallback } from 'react'
import { Box, Slider, Typography } from '@mui/material'
import { setControls, useControls } from '../../utils/useControls'

const TopNumber = () => {
  const { topN } = useControls('topN')
  const [displayValue, setDisplayValue] = React.useState(topN)

  const handleRangeSet = (_e, val) => {
    const value = parseInt(val, 10)
    setDisplayValue(value)
  }

  const handleRangeCommit = useCallback((_e, val) => {
    const value = parseInt(val, 10)
    setControls({ topN: value })
  }, [])

  return (
    <Box sx={{ mb: 3 }}>
      <Typography sx={{ mb: 1 }}>
        <b>Limit Results to Top</b>
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Slider
          sx={{ flex: 1, mr: 1 }}
          min={1}
          max={30}
          value={displayValue}
          onChange={handleRangeSet}
          onChangeCommitted={handleRangeCommit}
        />
        <Typography>{displayValue}</Typography>
      </Box>
    </Box>
  )
}

export default TopNumber
