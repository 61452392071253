import { useSession } from 'next-auth/react'
import { useEffect, useState } from 'react'
import App from '../src/App'
import LoadingPage from '../src/LoadingPage'
import { signIn } from '../src/SessionProvider'

export default function Home({ idsDomain }) {
  const [render, setRender] = useState(false)
  const { data: session, status } = useSession()

  useEffect(() => {
    if (session === null) {
      // Session is null if the user is not logged in or the session is expired.
      // https://next-auth.js.org/v3/tutorials/securing-pages-and-api-routes
      signIn()
    } else if (session) {
      window.session = session
      setRender(true)
    }
  }, [session, status])

  if (render) {
    return <App idsDomain={idsDomain} />
  } else {
    return <LoadingPage />
  }
}

export async function getStaticProps() {
  return {
    props: {
      idsDomain: process.env.IDS_DOMAIN,
    },
  }
}
