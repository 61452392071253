import React from 'react'
import { useEcData } from '../../utils/useEcData'
import MultiChart from './Charts/MultiChart'

const EcCharts = () => {
  const { histogramData, isHistogramLoading, dataSource } = useEcData()

  return isHistogramLoading == null ? null : (
    <MultiChart data={histogramData} loading={isHistogramLoading} dataSource={dataSource} />
  )
}

export default EcCharts
