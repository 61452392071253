import React, { Fragment } from 'react'
import { defaultSources } from '../../App'
import { useDashboardData } from '../../utils/useAppData'
import Histogram from './Histogram'
import Logs from './Logs'
import Map from './Map'
import Top from './Top'

const Dashboard = ({ fields }) => {
  const {
    dataSource,
    histogramLink,
    histogramData,
    isHistogramLoading,
    topLink1,
    topData1,
    topLink2,
    topData2,
    isLeftTopLoading,
    isRightTopLoading,
    mapLink,
    mapData,
    isMapLoading,
    logsLink,
    logsData,
    isLogsLoading,
    fetchTops,
    fetchHistogram,
    fetchLogs,
    fetchMap,
  } = useDashboardData(fields)

  return (
    <Fragment>
      <Histogram
        link={histogramLink}
        data={histogramData}
        fields={fields}
        isLoading={isHistogramLoading}
        fetchFunc={fetchHistogram}
      />
      <Top
        link={{ topLink1, topLink2 }}
        data={{ topData1, topData2 }}
        fields={fields}
        isRightTopLoading={isRightTopLoading}
        isLeftTopLoading={isLeftTopLoading}
        fetchFunc={fetchTops}
      />
      {!dataSource.startsWith('console/') &&
        defaultSources.some(
          ({ value, isMapDisabled }) => value === dataSource && !isMapDisabled,
        ) && (
          <Map
            mapLink={mapLink}
            data={mapData}
            isLoading={isMapLoading}
            fetchFunc={fetchMap}
            dataSource={dataSource}
          />
        )}
      <Logs
        logsLink={logsLink}
        data={logsData}
        isLoading={isLogsLoading}
        fs={fields}
        fetchFunc={fetchLogs}
      />
    </Fragment>
  )
}

export default Dashboard
