import React from 'react'
import { IgnoreKeys } from 'react-hotkeys'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { addFilter, useControls } from '../../utils/useControls'
import CardHeader from '../CardHeader'
import FieldAutocomplete from '../FieldAutocomplete'
import RefreshButton from '../GlobalControls/RefreshButton'
import LoadingIndicator from '../LoadingIndicator'
import { PieChart } from './Charts/PieChart'
import DownloadDataLink from './DownloadDataLink'

const Top = ({ data, fields, isLeftTopLoading, isRightTopLoading, fetchFunc }) => {
  const { topData1, topData2 } = data
  const { topField1, topField2 } = useControls('topField1', 'topField2')
  fields = fields.filter(({ isPiechartExclusion }) => !isPiechartExclusion)
  return (
    <Card className="card mb-5" sx={{ mb: 3, position: 'relative' }}>
      <CardHeader sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ mr: 1 }}>
          <strong>Top Results</strong>
        </Typography>
        <Box flex={1} />
        <RefreshButton fetchFunc={fetchFunc} />
        <DownloadDataLink data={topData1} name="the first pie chart" />
        <DownloadDataLink data={topData2} name="the second pie chart" />
      </CardHeader>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <CardContent sx={{ position: 'relative', width: '100%' }}>
          <LoadingIndicator loading={isLeftTopLoading} />
          <Grid>
            <Grid item md={6} xs={12}>
              <PieChart
                data={topData1}
                onCellClick={(entry) => addFilter(topField1, entry?.term, 'eq')}
              />
              <IgnoreKeys>
                <FieldAutocomplete
                  key={fields.length} // force a remount when fields change so that the current value is properly selected
                  disableClearable
                  fields={fields}
                  control="topField1"
                  value={topField1}
                  inputProps={{
                    fullWidth: true,
                  }}
                />
              </IgnoreKeys>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent sx={{ position: 'relative', width: '100%' }}>
          <LoadingIndicator loading={isRightTopLoading} />
          <Grid>
            <Grid item md={6} xs={12}>
              <PieChart
                data={topData2}
                onCellClick={(entry) => addFilter(topField2, entry?.term, 'eq')}
              />
              <IgnoreKeys>
                <FieldAutocomplete
                  key={fields.length} // force a remount when fields change so that the current value is properly selected
                  disableClearable
                  fields={fields}
                  control="topField2"
                  value={topField2}
                  inputProps={{
                    fullWidth: true,
                  }}
                />
              </IgnoreKeys>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  )
}

Top.propTypes = {
  data: PropTypes.object.isRequired,
  fields: PropTypes.array,
  isLeftTopLoading: PropTypes.bool.isRequired,
  isRightTopLoading: PropTypes.bool.isRequired,
  fetchFunc: PropTypes.func.isRequired,
}

export default Top
