import { Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import MyLogo from './logo.svg'

export default function Logo(props) {
  const { logoUrl } = props
  const theme = useTheme()
  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
      }}
      variant="h6"
      component="h1"
    >
      {logoUrl ? (
        <img src={logoUrl} alt="new" width={100} height={48} style={{ marginRight: 8 }} />
      ) : (
        <MyLogo height={48} style={{ marginRight: 8 }} />
      )}
      Insights
    </Typography>
  )
}

Logo.propTypes = {
  logoUrl: PropTypes.string,
}
