import React, { useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { setControls, useControls } from '../../../utils/useControls'
import CardHeader from '../../CardHeader'
import LoadingIndicator from '../../LoadingIndicator'

const MultiChart = ({ data, loading }) => {
  const theme = useTheme()
  const chartColors = theme.palette.chart
  const { resolution, interval } = useControls('resolution', 'interval')
  const [stackMode, setStackMode] = useState(false)
  const [selected, setSelected] = useState([0, 0])
  const resolutions = ['Auto', 'Second', 'Minute', 'Hour', 'Day']

  const setResolution = (res) => {
    setControls({
      resolution: res,
    })
  }

  const disableResolution = (value) => {
    // Disable seconds at 6 hours and above
    if (interval >= 60 * 60 * 6) {
      disableValues.push('second')
    }
    // Disable minutes at 7 days and above
    if (interval >= 60 * 60 * 24 * 7) {
      disableValues.push('minute')
    }
    return disableValues.includes(value.toLowerCase())
  }

  const toggleStackMode = () => {
    setStackMode((value) => !value)
  }

  const onSelectKey = (i, idx) => {
    setSelected((prev) => {
      const n = [...prev]
      n[idx] = i
      return n
    })
  }

  const rotateResolutions = () => {
    const currentIndex = resolutions.findIndex((res) => res.toLowerCase() === resolution)
    const findNextIndex = (index) => {
      const nextIndex = (index + 1) % resolutions.length
      if (disableResolution(resolutions[nextIndex])) {
        return findNextIndex(nextIndex)
      } else {
        return nextIndex
      }
    }
    const nextIndex = findNextIndex(currentIndex)
    const nextRes = resolutions[nextIndex].toLowerCase()
    setResolution(nextRes)
  }

  const keyMap = {
    TIMER: { name: 'Change time chart resolution', sequence: 'r' },
    VIEWER: { name: 'Change time chart display mode', sequence: 'v' },
  }

  const handlers = {
    TIMER: rotateResolutions,
    VIEWER: toggleStackMode,
  }

  const ChartDiv = ({ item, k }) => {
    return (
      <div style={{ width: '100%', height: 350, userSelect: 'none' }}>
        {!stackMode && (
          <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
            <b>{k}</b>
          </Typography>
        )}
        <ResponsiveContainer height={300}>
          <RechartsLineChart
            activeDot={{ r: 1 }}
            height={300}
            margin={{ top: 5, right: 20, bottom: 0, left: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              allowDataOverflow
              domain={['auto', 'auto']}
              tickFormatter={(tick) => moment(tick).format('M/D/YY hh:mma')}
              dataKey="time"
              type="number"
              tick={{ fontSize: 12, fill: theme.palette.text.primary }}
              scale="time"
              allowDuplicatedCategory={false}
            />
            <YAxis
              label={{
                value: 'Count',
                angle: -90,
                position: 'insideLeft',
                dx: -10,
                fill: theme.palette.text.primary,
              }}
              tick={{ fontSize: 12, fill: theme.palette.text.primary }}
            />
            <Tooltip labelFormatter={(t) => moment(t).format('M/D/YY hh:mma')} />
            <Legend
              wrapperStyle={{
                fontSize: '1.2rem',
                paddingBottom: '10px',
              }}
              verticalAlign="bottom"
              iconType="square"
            />
            {item.results &&
              Object.keys(item.results)
                .sort((a, b) => b.localeCompare(a))
                .map((s, i) => (
                  <Line
                    dataKey="count"
                    data={item.results?.[s]?.[k]}
                    name={s}
                    key={s}
                    isAnimationActive={false}
                    dot={false}
                    type="linear"
                    strokeWidth={1}
                    stroke={chartColors[i % chartColors.length]}
                  />
                ))}
          </RechartsLineChart>
        </ResponsiveContainer>
      </div>
    )
  }

  return (
    <Card>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges={true}>
        <CardHeader>
          <Box display="flex" flexDirection="row" gap={2} justifyContent="center">
            <Typography flex={1} variant="h6">
              <b>Timeline</b>
            </Typography>
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" gutterBottom>
                Resolution
              </Typography>
              <ToggleButtonGroup
                exclusive
                value={resolution}
                onChange={(e, value) => setResolution(value)}
              >
                {resolutions
                  .filter((v) => !disableResolution(v.toLowerCase()))
                  .map((v) => (
                    <ToggleButton key={v} value={v.toLowerCase()}>
                      {v}
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" gutterBottom>
                View As
              </Typography>
              <ToggleButtonGroup
                size="small"
                value={stackMode}
                exclusive
                onClick={(e, value) => toggleStackMode(value)}
              >
                <ToggleButton value={true}>Single Chart</ToggleButton>
                <ToggleButton value={false}>Stacked Charts</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <div style={{ flex: 1 }} />
          </Box>
        </CardHeader>
      </GlobalHotKeys>
      <CardContent sx={{ position: 'relative', minHeight: 400 }}>
        <LoadingIndicator loading={loading} />
        {data &&
          data.map((item, idx) => {
            return item.keys ? (
              <div key={item.id}>
                <Typography variant="h6">{item.name}</Typography>
                <Box sx={{ mb: 2 }} display="flex" alignItems="center">
                  {stackMode && (
                    <>
                      <Typography sx={{ mr: 2 }}>Show:</Typography>
                      {item.keys.map((k, i) => (
                        <FormControlLabel
                          key={i}
                          label={k}
                          value={i}
                          control={
                            <Radio
                              onClick={(_e) => onSelectKey(i, idx)}
                              checked={i === selected[idx]}
                            />
                          }
                        />
                      ))}
                    </>
                  )}
                </Box>

                {stackMode ? (
                  <ChartDiv item={item} k={item.keys[selected[idx]]} />
                ) : (
                  item.keys.map((k, i) => <ChartDiv item={item} k={k} key={i} />)
                )}
              </div>
            ) : null
          })}
      </CardContent>
    </Card>
  )
}

MultiChart.propTypes = {
  data: PropTypes.array.isRequired,
}

export default MultiChart
