import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Area,
  CartesianGrid,
  Legend,
  Line,
  AreaChart as RechartsAreaChart,
  LineChart as RechartsLineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { formatBigNumber, truncateSize } from '../../../utils/dataFormatting'
import CardHeader from '../../CardHeader'

const SingleLineChart = ({ data, avgMode, zoomState, height, field, syncId, ...handlers }) => {
  const theme = useTheme()
  const chartColors = theme.palette.chart
  const { handleMouseDown, handleMouseMove, handleMouseUp } = handlers
  const otherKeys = useMemo(() => {
    const { time: _time, ...otherKeys } = data?.[data.length - 1] || {}
    return otherKeys
  }, [data])
  const bucket = data && data[1] ? (data[1].time - data[0].time) / 1000 : 1

  const keys = useMemo(
    () => (field ? [field] : Object.keys(otherKeys).sort((a, b) => a.localeCompare(b))),
    [otherKeys, field],
  )
  const init = [...Array(keys.length)].map((u, i) => i)
  const [selected, setSelected] = useState(init)

  useEffect(() => {
    setSelected([...Array(keys.length)].map((u, i) => i))
  }, [keys])

  const [Chart, Series] = keys.length === 1 ? [RechartsAreaChart, Area] : [RechartsLineChart, Line]

  const handleHertzToggle = (n) => {
    return formatBigNumber(avgMode ? Math.round(n / bucket) : n)
  }

  const handleLocalMouseDown = (e) => {
    return e === null ? false : handleMouseDown(e)
  }

  const handleLocalMouseUp = (e) => {
    return e === null ? false : handleMouseUp(e)
  }

  const handleOnClick = (e, idx, c) => {
    if (c.shiftKey) {
      setSelected(() => init.filter((i) => i === idx))
    } else {
      if (selected.includes(idx)) {
        setSelected((prev) => prev.filter((i) => i !== idx))
      } else {
        setSelected((prev) => [...prev, idx])
      }
    }
  }

  const CustomTooltip = ({ payload = [], active, label }) => {
    if (active && payload) {
      const sortedItems = [...payload].sort((a, b) => b.value - a.value)
      const { hasValue, noValue } = sortedItems.reduce(
        (result, item) => {
          if (item.value) {
            result.hasValue.push(item)
          } else {
            result.noValue.push(item)
          }
          return result
        },
        { hasValue: [], noValue: [] },
      )

      const Item = ({ color, name, value }) => {
        let result
        try {
          result = (avgMode ? value / bucket : value).toFixed(2)
        } catch (e) {}
        return (
          <Typography key={name} variant="body2" sx={{ color }}>
            {keys.length > 1 && <span>{truncateSize(name)}: </span>}
            {+result}
          </Typography>
        )
      }

      return (
        <Card elevation={5}>
          <CardHeader>
            <Typography variant="body2">
              <b>{moment(label).format('M/D/YY hh:mma')}</b>
            </Typography>
          </CardHeader>
          <CardContent sx={{ pb: 0, mb: 0 }}>
            {hasValue.map(Item)}
            {noValue.length > 0 && noValue.map(Item)}
          </CardContent>
        </Card>
      )
    }

    return null
  }

  return (
    <div style={{ width: '100%', height: height, userSelect: 'none' }} data-test-hook="line-chart">
      <ResponsiveContainer height={height}>
        <Chart
          syncId={syncId}
          onMouseDown={handleLocalMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleLocalMouseUp}
          activeDot={{ r: 1 }}
          height={height}
          data={data}
          margin={{ top: 5, right: 20, bottom: 0, left: 20 }}
        >
          {keys.length > 1 && (
            <Legend
              wrapperStyle={{ fontSize: '0.9rem', paddingBottom: '16px' }}
              verticalAlign="top"
              onClick={handleOnClick}
              payload={keys
                .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
                .map((item, idx) => ({
                  dataKey: item,
                  type: selected.includes(idx) ? 'square' : 'line',
                  value: item,
                  color: chartColors[idx % chartColors.length],
                }))}
            />
          )}
          <CartesianGrid strokeDasharray="4 4" />
          {keys
            .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
            .map((key, index) => {
              return (
                <Series
                  isAnimationActive={false}
                  key={key}
                  dot={false}
                  type="linear"
                  dataKey={selected.includes(index) ? key : undefined}
                  stroke={chartColors[index % chartColors.length]}
                  strokeWidth={1.5}
                />
              )
            })}

          <XAxis
            allowDataOverflow
            domain={[zoomState.left, zoomState.right]}
            tickFormatter={(tick) => moment(tick).format('M/D/YY hh:mma')}
            dataKey="time"
            type="number"
            tick={{
              fontSize: 12,
              fill: theme.palette.text.primary,
            }}
            scale="time"
          />
          <YAxis
            tickFormatter={handleHertzToggle}
            label={{
              value: avgMode ? 'Events/second' : 'Count',
              angle: -90,
              position: 'insideLeft',
              dx: -10,
              dy: avgMode ? 60 : 0,
              fill: theme.palette.text.primary,
            }}
            tick={{ fontSize: 12, fill: theme.palette.text.primary }}
          />

          {zoomState.refAreaLeft && zoomState.refAreaRight && (
            <ReferenceArea
              x1={zoomState.refAreaLeft}
              x2={zoomState.refAreaRight}
              strokeOpacity={0.3}
            />
          )}
          <Tooltip content={CustomTooltip} />
        </Chart>
      </ResponsiveContainer>
    </div>
  )
}

export default function LineChart(props) {
  const { data, layout } = props

  return layout === 'multiple' ? (
    <Grid container spacing={5}>
      {data.length &&
        Object.keys(data[0])
          .filter((k) => k !== 'time')
          .sort((a, b) => a.localeCompare(b))
          .map((field) => (
            <Grid item xs={12} md={6} xl={4} key={field}>
              <Typography gutterBottom sx={{ fontWeight: 'bold', pl: '60px', textAlign: 'center' }}>
                {field}
              </Typography>
              <SingleLineChart syncId="line-chart" {...props} field={field} height={300} />
            </Grid>
          ))}
    </Grid>
  ) : (
    <SingleLineChart {...props} field={undefined} height={500} />
  )
}

LineChart.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  handleMouseDown: PropTypes.func.isRequired,
  handleMouseMove: PropTypes.func.isRequired,
  handleMouseUp: PropTypes.func.isRequired,
}
