import { Box, CircularProgress, alpha, useTheme } from '@mui/material'
import PropTypes from 'prop-types'

export default function LoadingIndicator({ loading, opaque }) {
  const theme = useTheme()

  if (loading) {
    return (
      <Box
        position="absolute"
        height="100%"
        width="100%"
        top={0}
        left={0}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          background: alpha(theme.palette.background.default, opaque ? 1 : 0.8),
          zIndex: 1,
        }}
      >
        <CircularProgress size={48} />
      </Box>
    )
  } else {
    return null
  }
}

LoadingIndicator.propTypes = {
  opaque: PropTypes.bool,
  loading: PropTypes.bool,
}
