import React from 'react'
import {
  Card,
  CardActions,
  CardContent,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import { setControls, useControls } from '../../utils/useControls'
import CardHeader from '../CardHeader'
import RefreshButton from '../GlobalControls/RefreshButton'
import LoadingIndicator from '../LoadingIndicator'
import DownloadDataLink from './DownloadDataLink'
import Linkify from './Linkify'
import { LogModal } from './LogModal'

const StyledTableCell = styled(TableCell)(() => ({
  '&': {
    cursor: 'pointer',
  },
}))

const StyledTableRow = styled(TableRow)(() => ({}))

const Logs = ({ logsLink, data, fs, isLoading, fetchFunc }) => {
  const { dataSource, perPage, currentPage } = useControls('dataSource', 'perPage', 'currentPage')
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedLog, setSelectedLog] = React.useState({})

  const setCurrentPage = (value) => {
    setControls({
      currentPage: value,
    })
  }
  const setPageSize = (value) => {
    setControls({
      perPage: value,
    })
  }

  const closeModal = () => {
    setSelectedLog({})
    setIsModalOpen(false)
  }

  const fieldsMap = {
    waf: ['timestamp', 'common_header.virt_remote_host', 'profile_type', 'msg'],
    'waf-stage': ['timestamp', 'common_header.virt_remote_host', 'profile_type', 'msg'],
    serverlesslog: ['timestamp', 'level', 'function_id', 'message'],
    'serverlesslog-stage': ['timestamp', 'level', 'function_id', 'message'],
    rate_limiting: [
      'timestamp',
      'common_header.host',
      'common_header.x_forwarded_for',
      'action_type',
    ],
    'rate-limiting-stage': [
      'timestamp',
      'common_header.host',
      'common_header.x_forwarded_for',
      'action_type',
    ],
    accesslog: [
      'timestamp',
      'common_header.host',
      'orig_url',
      'common_header.x_forwarded_for',
      'user_agent',
    ],
    'accesslog-bf': [
      'timestamp',
      'common_header.host',
      'orig_url',
      'common_header.x_forwarded_for',
      'user_agent',
    ],
    'accesslog-sc-bf': [
      'timestamp',
      'common_header.host',
      'orig_url',
      'common_header.x_forwarded_for',
      'user_agent',
    ],
    'accesslog-stage': [
      'timestamp',
      'common_header.host',
      'orig_url',
      'common_header.x_forwarded_for',
      'user_agent',
    ],
    bot: ['timestamp', 'common_header.host', 'common_header.x_forwarded_for', 'user_agent'],
    'bot-stage': ['timestamp', 'common_header.host', 'common_header.x_forwarded_for', 'user_agent'],
    analytics: ['timestamp', 'common_header.host', 'common_header.x_forwarded_for', 'user_agent'],
    'accesslog-internal': [
      'timestamp',
      'common_header.host',
      'orig_url',
      'common_header.x_forwarded_for',
      'user_agent',
    ],
    'rumlog-stage': ['timestamp', 'hostname', 'path', 'title', 'lcp', 'cls', 'fid', 'ttfb', 'inp'],
    rumlog: ['timestamp', 'hostname', 'path', 'title', 'lcp', 'cls', 'fid', 'ttfb', 'inp'],
    'bassfish/nel': ['timestamp', 'host', 'type', 'user_agent'],
    'bassfish/stage/nel': ['timestamp', 'host', 'type', 'user_agent'],
    'bassfish/csp': ['Bucket', 'BlockedUriUrl', 'DocumentUriDomain', 'DocumentUriPath'],
    'bassfish/stage/csp': ['Bucket', 'BlockedUriUrl', 'DocumentUriDomain', 'DocumentUriPath'],
    edgefunctionslog: ['timestamp', 'customer_id', 'function_name'],
    'edgefunctionslog-stage': ['timestamp', 'customer_id', 'function_name'],
    'console/Activity': ['timestamp', 'action', 'organization', 'environment_string'],
    'console/AI': ['timestamp', 'purpose', 'model', 'success'],
    'console/deployments': ['timestamp', 'status', 'organization', 'environment'],
    'console/organizations': ['timestamp', 'name', 'tier', 'creator'],
    'console/users': ['timestamp', 'fullname', 'email', 'created_at'],
    security: ['timestamp', 'host', 'action_type', 'user_agent'],
    'security-stage': ['timestamp', 'host', 'action_type', 'user_agent'],
  }

  const getValue = (r, k) => {
    if (k?.includes('.')) {
      const [f, l] = k.split('.')
      return r[f] ? r[f][l] : ''
    } else {
      return k === 'timestamp' ? moment(r[k]).format('M/D/YY hh:mma') : r[k]
    }
  }

  const getTitle = (t) => {
    if (t === 'orig_url') {
      return 'Path'
    } else {
      const filter = fs.find((f) => f.value === t)
      if (filter) {
        return filter.label
      } else if (t?.includes('.')) {
        const str = t.split('.')
        return str[1].replace(/_/g, ' ')
      } else {
        return t.replace(/_/g, ' ')
      }
    }
  }

  const fields = []

  try {
    let mapForDataSource = fieldsMap[dataSource]

    if (!mapForDataSource) {
      mapForDataSource = fs.map((field) => field.value)
    }

    mapForDataSource.forEach((e) => {
      fields[e] = {
        width: '100%',
        label: getTitle(e),
        display: getValue,
      }
    })
  } catch (e) {}

  const tableLayout = fields.length <= 5 ? 'fixed' : 'auto'
  return (
    <>
      <Card elevation={3} sx={{ mb: 3 }}>
        <CardHeader
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">
            <b>Logs</b>
          </Typography>
          <div style={{ flex: 1 }} />
          <RefreshButton fetchFunc={fetchFunc} />
          <DownloadDataLink data={data} />
        </CardHeader>
        <CardContent sx={{ position: 'relative' }}>
          <LoadingIndicator loading={isLoading} />
          <TableContainer component={Paper} variant="outlined">
            <Table size="small" data-test-hook="logs-table" sx={{ tableLayout }}>
              <TableHead className="thead-light">
                <TableRow>
                  {Object.entries(fields).map(([key, value], i) => (
                    <TableCell
                      key={key}
                      style={{
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        width: i === 0 ? 180 : undefined,
                      }}
                    >
                      <b>{value.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.events?.length ? (
                  data.events.map((row, index) => (
                    <StyledTableRow
                      hover
                      className="show-pointer"
                      key={index}
                      onClick={() => {
                        setIsModalOpen(true)
                        setSelectedLog(row)
                      }}
                    >
                      {Object.entries(fields).map(([key, value], _i) => (
                        <StyledTableCell
                          key={key}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <Tooltip title={value.display(row, key)?.toString()} placement="top">
                            <code>
                              <Linkify value={value.display(row, key)} />
                            </code>
                          </Tooltip>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={Object.keys(fields).length} style={{ textAlign: 'center' }}>
                      <em className="text-muted">No log data returned</em>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CardActions
            sx={{
              mt: 3,
              mb: -1,
              mx: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <div style={{ flex: 1 }} />
            <div>Logs per page:</div>
            <Select
              sx={{ mr: 2 }}
              size="small"
              value={perPage}
              onChange={(e) => {
                setCurrentPage(1)
                setPageSize(e.target.value)
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Pagination
              showFirstButton
              showLastButton
              count={data.page_of}
              page={currentPage}
              siblingCount={1}
              boundaryCount={2}
              onChange={(e, page) => {
                setCurrentPage(page)
              }}
            />
            <div style={{ flex: 1 }} />
          </CardActions>
        </CardContent>
      </Card>
      <LogModal
        data={selectedLog}
        isOpen={isModalOpen}
        toggle={closeModal}
        fs={fs}
        logsLink={logsLink}
      />
    </>
  )
}

Logs.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchFunc: PropTypes.func.isRequired,
}

export default Logs
