import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { formatBigNumber } from '../../utils/dataFormatting'
import DownloadDataLink from '../Dashboard/DownloadDataLink'
import Filters from './Filters/Filters'
import Intervals from './Intervals'
import Navigate from './Navigate'
import { Refresh } from './Refresh'
import { SourceSelector } from './SourceSelector'
import TimeRange from './TimeRange'
import TopNumber from './TopNumber'

const GlobalControls = ({ data, fields, datasource }) => {
  const theme = useTheme()

  return (
    <div style={{ margin: theme.spacing(0, 1) }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Typography variant="h6">
          <span style={{ opacity: 0.7 }}>Total Events:</span> <b>{formatBigNumber(data.count)}</b>
        </Typography>
        <DownloadDataLink data={data} />
      </div>
      <br />
      <div>
        <SourceSelector />
        <Intervals />
        <TimeRange />
        <Navigate />
        <Refresh />
        <TopNumber />
        <Filters fields={fields} datasource={datasource} />
      </div>
    </div>
  )
}

export default GlobalControls
