import React from 'react'
import Add from '@mui/icons-material/Add'
import Remove from '@mui/icons-material/Remove'
import {
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { addFilter, setControls, useControls } from '../../utils/useControls'
import CardHeader from '../CardHeader'
import RefreshButton from '../GlobalControls/RefreshButton'
import LoadingIndicator from '../LoadingIndicator'
import { WorldMap } from './Charts/WorldMap'
import DownloadDataLink from './DownloadDataLink'

const Map = ({ data, isLoading, fetchFunc, dataSource }) => {
  const { mapType } = useControls('mapType')

  const [position, setPosition] = React.useState({
    coordinates: [0, 0],
    zoom: 1,
  })

  const changeMapType = (_event, mapType) => {
    setControls({
      mapType,
    })
  }

  const handleZoomIn = () => {
    if (position.zoom < 4) {
      setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }))
    }
  }

  const handleZoomOut = () => {
    if (position.zoom > 1) {
      setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }))
    }
  }

  return (
    <Card elevation={3} sx={{ mb: 3 }}>
      <CardHeader sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h6">
          <b>Map</b>
        </Typography>
        <div style={{ flex: 1 }} />
        <ToggleButtonGroup exclusive value={mapType} color="primary" onChange={changeMapType}>
          <ToggleButton value="pop">POP</ToggleButton>
          <ToggleButton value="srcip">Source IP</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup>
          <Tooltip title="Zoom In">
            <ToggleButton
              type="button"
              value="in"
              className="btn btn-xxs btn-primary px-4"
              onClick={handleZoomIn}
            >
              <Add />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Zoom Out">
            <ToggleButton
              type="button"
              value="out"
              className="btn btn-xxs btn-primary px-4"
              onClick={handleZoomOut}
            >
              <Remove />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <RefreshButton fetchFunc={fetchFunc} />
        <DownloadDataLink data={data} />
      </CardHeader>
      <CardContent data-test-hook="pops-map" sx={{ position: 'relative' }}>
        <LoadingIndicator loading={isLoading} />
        <WorldMap
          data={data}
          mapType={mapType}
          dataSource={dataSource}
          addFilter={addFilter}
          position={position}
          setPosition={setPosition}
        />
      </CardContent>
    </Card>
  )
}

Map.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchFunc: PropTypes.func.isRequired,
  dataSource: PropTypes.string.isRequired,
}

export default Map
