import React, { useMemo } from 'react'
import { Paper, Typography, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { Cell, Pie, PieChart as RechartsPieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { chartGray } from '../../../utils/chartColors'
import sort from '../../../utils/sort'

export const PieChart = ({ data, onCellClick }) => {
  const theme = useTheme()
  const chartColors = theme.palette.chart
  const { results = [], total = 0 } = data

  const CustomTooltip = ({ active, payload }) => {
    if (active) {
      const percentage = (payload[0].value / total) * 100
      return (
        <Paper elevation={3} sx={{ p: 2, opacity: 0.9 }}>
          <Typography>
            {payload[0].name}: {percentage.toFixed(2)}%
          </Typography>
        </Paper>
      )
    }
    return null
  }

  // Here we ensure that the colors match the line chart by choosing the color based
  // on the alhpabetical order of the term
  const colors = useMemo(() => {
    const sortedResults = sort(results, 'term')
    const colors = { 'Rest...': chartGray }

    sortedResults
      .filter(({ term }) => term !== 'Rest...')
      .forEach((entry, i) => {
        colors[entry.term] = chartColors[i % chartColors.length]
      })

    return colors
  }, [chartColors, results])

  const renderCell = (entry, _index) => {
    const isRest = entry.term === 'Rest...'
    const handleClick = (e) => {
      if (!isRest) {
        onCellClick(entry, e.shiftKey)
      }
    }

    return (
      <Cell
        key={entry.term}
        onClick={handleClick}
        fill={colors[entry.term]}
        style={{ cursor: isRest ? 'initial' : 'pointer' }}
      />
    )
  }

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer height={300}>
        <RechartsPieChart>
          <Pie
            label={(i) => (i.percent > 0.02 ? i.name : '')}
            labelLine={false}
            dataKey="count"
            isAnimationActive={false}
            nameKey="term"
            data={results}
          >
            {results.map(renderCell)}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </RechartsPieChart>
      </ResponsiveContainer>
    </div>
  )
}

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  onCellClick: PropTypes.func.isRequired,
}
