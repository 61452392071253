import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import PropTypes from 'prop-types'
import { formatBigNumber } from '../../../utils/dataFormatting'

const ParseNumber = ({ k, v }) => {
  if (k.includes('bounds')) {
    return (
      <span>
        -{formatBigNumber(Math.round(Math.abs(v.lower)))} /{formatBigNumber(Math.round(v.upper))}
      </span>
    )
  }
  if (v < 100) {
    return formatBigNumber(v)
  } else {
    return formatBigNumber(Math.round(v))
  }
}

const DataTable = ({ data }) => {
  if (Array.isArray(data)) {
    return null
  }

  let isSingle = false,
    listAttrs,
    listKeys

  if (typeof Object.values(data)[0] === 'number') {
    isSingle = true
    delete data.time_from
    delete data.time_to
  } else {
    listAttrs = Object.keys(data)
    listKeys = Object.keys(Object.values(data)[0])
    listKeys = listKeys.filter((v) => !v.includes('time'))
  }

  return (
    <TableContainer>
      <Table size="small">
        {isSingle ? (
          <TableBody>
            {Object.entries(data).map(([k, v]) => (
              <TableRow key={k}>
                <TableCell>
                  <code>{k}</code>
                </TableCell>
                <TableCell>
                  <code>
                    <ParseNumber k={k} v={v} />
                  </code>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              {listAttrs.map((k) => (
                <TableCell key={k}>
                  <b>{k}</b>
                </TableCell>
              ))}
            </TableRow>
            {listKeys.map((j, idx) => (
              <TableRow key={idx}>
                <TableCell>
                  <b>{j}</b>
                </TableCell>
                {listAttrs.map((i, index) => (
                  <TableCell key={index}>
                    <code>
                      <ParseNumber k={j} v={data[i][j]} />
                    </code>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export default DataTable

DataTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}
