import { useEffect, useState } from 'react'
import { defaultSources } from '../App'
import { chartTypes } from '../components/Dashboard/Analytics'
import {
  formatAnalyticData,
  formatHistogramData,
  formatTopData,
  getContext,
} from './dataFormatting'
import { fetchMultiple, getAppData } from './fetch'
import { getControl, setControls, useControls } from './useControls'

export const useFields = (dataSource, defaultSources) => {
  const [fields, setFields] = useState(null)

  // Sync fields once
  useEffect(() => {
    const fetchFields = async () => {
      try {
        const field = getControl('field')
        const histogramType = getControl('histogramType')
        const { data: fieldsResponse } = await getAppData(dataSource, 'fields')
        let formattedFields = fieldsResponse.fields.map(
          ({
            name,
            friendly_name,
            keywords,
            data_type,
            is_analyzed,
            units,
            isPiechartExclusion,
          }) => ({
            keywords,
            label: !units ? friendly_name : friendly_name + ' (' + units + ')',
            value: name,
            type: data_type,
            isAnalyzed: is_analyzed === 'true',
            isPiechartExclusion: isPiechartExclusion === 'true',
            units: units,
          }),
        )
        const context = getContext()
        const excludedFields = []
        if (context?.team) {
          excludedFields.push('team_id')
          excludedFields.push('organization_id')
        }
        if (context?.environment) {
          excludedFields.push('environment_id')
        }
        if (context?.an) {
          excludedFields.push('an')
        }
        if (excludedFields) {
          formattedFields = formattedFields.filter(({ value }) => !excludedFields.includes(value))
        }
        context?.environment
        let isFieldUnavailable =
          !formattedFields || !formattedFields.some((i) => i.value === field && i.isAnalyzed)
        if (histogramType !== 'histogram' && isFieldUnavailable) {
          let firstAnalyzed = formattedFields.find((i) => i.isAnalyzed)
          if (firstAnalyzed) {
            setControls({ field: firstAnalyzed.value })
          } else {
            setControls({ histogramType: 'histogram' })
          }
        }
        setFields(formattedFields)
      } catch (error) {
        console.log(`Failed to load available fields. (${error.message})`)
      }
    }
    fetchFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, defaultSources])

  return fields
}

export const useCount = (dataSource, params) => {
  const [count, setCount] = useState(0)
  const [link, setLink] = useState('')
  const { filters, startTime, endTime } = params
  const filterString = JSON.stringify(filters)

  // Sync count with filters and times
  useEffect(() => {
    const fetchCount = async () => {
      try {
        const { link: countLink, data: countResponse } = await getAppData(
          dataSource,
          'count',
          params,
        )
        setCount(countResponse.count)
        setLink(countLink)
      } catch (error) {
        console.log(`Failed to load Total Event Count. (${error.message})`)
      }
    }
    fetchCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, filterString, startTime, endTime])

  return { link: link, count: count }
}

const abortControllers = {}

export const useDashboardData = (fields) => {
  let {
    dataSource,
    histogramType,
    filters,
    topN,
    startTime,
    endTime,
    resolution,
    selectedTopField,
    selectedWafType,
    topField1,
    topField2,
    mapType,
    perPage,
    currentPage,
    field,
    prefix,
    quantiles,
  } = useControls(
    'dataSource',
    'histogramType',
    'filters',
    'topN',
    'startTime',
    'endTime',
    'resolution',
    'selectedTopField',
    'selectedWafType',
    'topField1',
    'topField2',
    'mapType',
    'perPage',
    'currentPage',
    'field',
    'prefix',
    'quantiles',
  )

  const filterString = JSON.stringify(filters)
  const [histogramLink, setHistogramLink] = useState('')
  const [histogramData, setHistogramData] = useState([])
  const [topLink1, setTopLink1] = useState('')
  const [topData1, setTopData1] = useState({})
  const [topLink2, setTopLink2] = useState('')
  const [topData2, setTopData2] = useState({})
  const [mapLink, setMapLink] = useState('')
  const [mapData, setMapData] = useState([])
  const [logsLink, setLogsLink] = useState('')
  const [logsData, setLogsData] = useState({ events: [], page_of: 0 })

  const [isHistogramLoading, setIsHistogramLoading] = useState(true)
  const [isLeftTopLoading, setIsLeftTopLoading] = useState(true)
  const [isRightTopLoading, setIsRightTopLoading] = useState(true)
  const [isMapLoading, setIsMapLoading] = useState(true)
  const [isLogsLoading, setIsLogsLoading] = useState(true)

  const abortInFlightRequests = (scope) => {
    abortControllers[scope]?.abort()
    return (abortControllers[scope] = new AbortController())
  }

  const fetchHistogram = async (topField, topData) => {
    setIsHistogramLoading(true)
    if (selectedTopField && !topData) {
      topField = { 1: topField1, 2: topField2 }[selectedTopField]
      topData = { 1: topData1, 2: topData2 }[selectedTopField]
      if (Object.keys(topData).length === 0) {
        return
      }
    }

    let sourceKeys = []
    const promises = []

    const getHistogram = (
      filter = {},
      histogramEndpoint = undefined,
      sources = undefined,
      enableAbort = true,
    ) => {
      const extra = chartTypes.some((i) => i.value === histogramType)

      return getAppData(
        dataSource,
        histogramEndpoint || histogramType,
        {
          filters: [...filters, filter],
          resolution,
          topN,
          startTime,
          endTime,
          field: extra ? field : undefined,
          prefix: extra ? prefix : undefined,
          quantiles: extra ? quantiles : undefined,
          sources: sources,
        },
        enableAbort ? abortInFlightRequests('histogram') : null,
      )
    }

    if (selectedTopField && topData.results) {
      if (dataSource.startsWith('bassfish')) {
        sourceKeys = topData.results.map(({ term }) => term)
        promises.push(
          getHistogram({}, histogramType + '/' + topField, sourceKeys.toString(), false),
        )
      } else {
        topData.results.forEach(({ term }) => {
          if (term !== 'Rest...') {
            sourceKeys.push(term)
            promises.push(
              getHistogram(
                { field: topField, value: term, active: true, operatorId: 'eq' },
                undefined,
                undefined,
                false,
              ),
            )
          }
        })
      }
    } else {
      if (dataSource !== 'waf' && dataSource !== 'waf-stage') {
        promises.push(getHistogram())
      } else {
        if (histogramType !== 'histogram') {
          promises.push(
            getHistogram({
              field: 'action_type',
              value: selectedWafType,
              active: true,
              operatorId: 'eq',
            }),
          )
        } else {
          promises.push(
            getHistogram({ field: 'action_type', value: 'ALL', active: true, operatorId: 'eq' }),
          )
        }
      }
    }

    try {
      const histogramResponse = await fetchMultiple(promises)
      const formattedData =
        histogramType === 'histogram'
          ? formatHistogramData(histogramResponse, dataSource, sourceKeys)
          : formatAnalyticData(histogramResponse, histogramType, sourceKeys)
      setHistogramData(formattedData)
      setHistogramLink(histogramResponse[0]?.link)
      setIsHistogramLoading(false)
    } catch (error) {
      setHistogramData([])
      console.log(`Failed to load time chart data. (${error.message})`)
    }
  }
  const fetchTops = async () => {
    await fetchTop(2)
    await fetchTop(1)
  }
  const fetchTop = async (num) => {
    if (num === 1) {
      setIsLeftTopLoading(true)
    } else {
      setIsRightTopLoading(true)
    }
    const field = { 1: topField1, 2: topField2 }[num]

    if (fields && !fields.find(({ value }) => value === field)) {
      const dataSourceDefaults = defaultSources.find(({ value }) => value === dataSource)

      if (num === 1) {
        setIsLeftTopLoading(true)
        setControls({ topField1: dataSourceDefaults.t1 })
      } else {
        setIsRightTopLoading(true)
        setControls({ topField2: dataSourceDefaults.t2 })
      }
      return
    }
    if (field === 'null' || field == null) {
      return
    }

    const setTopData = { 1: setTopData1, 2: setTopData2 }[num]
    const setTopLink = { 1: setTopLink1, 2: setTopLink2 }[num]

    try {
      const { link: topLink, data: topResponse } = await getAppData(
        dataSource,
        'top',
        {
          filters,
          topN,
          startTime,
          endTime,
          field,
        },
        abortInFlightRequests(`top${num}`),
      )
      const formattedData = formatTopData(topResponse)
      setTopLink(topLink)
      setTopData(formattedData)
      if (num === 1) {
        setIsLeftTopLoading(false)
      } else {
        setIsRightTopLoading(false)
      }
      if (selectedTopField === num) {
        await fetchHistogram(field, formattedData)
      }
    } catch (error) {
      console.log(`Failed to load "${field}" data for pie chart ${num}. (${error.message})`)
    }
  }

  // Sync top1 data with params and selected field
  useEffect(() => {
    fetchTop(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, topField1, filterString, topN, startTime, endTime])

  // Sync top2 data with params and selected field
  useEffect(() => {
    fetchTop(2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, topField2, filterString, topN, startTime, endTime])

  useEffect(() => {
    setIsHistogramLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource])
  // Sync histogram with params, resolution, and selected top field
  useEffect(() => {
    fetchHistogram()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields,
    histogramType,
    resolution,
    field,
    selectedTopField,
    selectedWafType,
    filterString,
    topN,
    startTime,
    endTime,
    quantiles,
  ])

  const mapEndpoint = {
    srcip: 'topgeo',
    pop: 'topgeopop',
  }[mapType]

  const fetchMap = async () => {
    setIsMapLoading(true)

    try {
      const { link: mapLink, data: mapResponse } = await getAppData(
        dataSource,
        mapEndpoint,
        {
          filters,
          topN,
          startTime,
          endTime,
        },
        abortInFlightRequests('map'),
      )
      setMapLink(mapLink)
      setMapData(mapResponse.terms)
      setIsMapLoading(false)
    } catch (error) {
      console.log(`Failed to load "${mapType}" map data. (${error.message})`)
    }
  }
  // Sync map with params and map type
  useEffect(() => {
    if (defaultSources.some(({ value, isMapDisabled }) => value === dataSource && !isMapDisabled)) {
      fetchMap()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, mapType, filterString, topN, startTime, endTime])

  const fetchLogs = async () => {
    setIsLogsLoading(true)

    try {
      const { link: logsLink, data: logsResponse } = await getAppData(
        dataSource,
        'log',
        {
          filters,
          topN,
          startTime,
          endTime,
          perPage,
          currentPage,
        },
        abortInFlightRequests('logs'),
      )
      setLogsLink(logsLink)
      setLogsData(logsResponse)
      setIsLogsLoading(false)
    } catch (error) {
      console.log(`Failed to load event logs. (${error.message})`)
    }
  }
  // Sync logs with filters and pages
  useEffect(() => {
    fetchLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, perPage, currentPage, filterString, topN, startTime, endTime])

  return {
    dataSource,
    histogramLink,
    histogramData,
    histogramType,
    isHistogramLoading,
    topLink1,
    topData1,
    topLink2,
    topData2,
    isLeftTopLoading,
    isRightTopLoading,
    mapLink,
    mapData,
    isMapLoading,
    logsLink,
    logsData,
    isLogsLoading,
    fetchTops,
    fetchHistogram,
    fetchLogs,
    fetchMap,
  }
}
