import { useSession } from 'next-auth/react'
import React, { Fragment, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IgnoreKeys } from 'react-hotkeys'
import { Clear, Search } from '@mui/icons-material'
import Close from '@mui/icons-material/Close'
import Copy from '@mui/icons-material/ContentCopy'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import AppContext from '../../AppContext'
import { getAppData } from '../../utils/fetch'
import isStaff from '../../utils/isStaff'
import { useControls } from '../../utils/useControls'
import DownloadDataLink from './DownloadDataLink'
import Linkify from './Linkify'

const DataTable = React.memo(({ data: tableData }) => {
  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ mb: 2, mt: 1, width: '100%', overflowX: 'hidden' }}
    >
      <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
        <TableBody>
          {tableData.map(({ key, value, label }) => {
            return (
              <TableRow key={key}>
                <TableCell sx={{ width: '30%' }}>
                  {label || key}
                  {!!label && (
                    <Typography variant="caption" sx={{ opacity: 0.6, display: 'block' }}>
                      {key}
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <Tooltip title={value?.toString()} placement="top">
                    <code>
                      <Linkify value={value} />
                    </code>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ cursor: 'pointer', width: 60 }}>
                  <IconButton size="small" title="Copy to clipboard">
                    <CopyToClipboard text={value}>
                      <Copy />
                    </CopyToClipboard>
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
})

DataTable.displayName = 'DataTable'

function humanize(str) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const SectionHeader = ({ sx, ...props }) => (
  <Typography variant="body2" {...props} sx={{ fontWeight: 'bold', ml: 1, opacity: 0.5, ...sx }} />
)

export const LogModal = memo(({ data = {}, fs, isOpen, toggle }) => {
  const staff = isStaff(useSession())
  const [search, setSearch] = useState('')
  const { dataSource } = useControls('dataSource')
  const requestId = data?.req_uuid || data?.request_id
  const [selectedDataSource, setSelectedDataSource] = useState(null)
  const { dataSources } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [logEntriesByDataSource, setLogEntriesByDataSource] = useState({})

  useEffect(() => {
    setLogEntriesByDataSource({ [dataSource]: data })
    setSelectedDataSource(dataSources?.find((ds) => ds.value === dataSource))
  }, [requestId, dataSource, data])

  useEffect(() => {}, [selectedDataSource, logEntriesByDataSource])

  /**
   * When the user selects a different data source tab, fetch the log entries for that data source.
   */
  const handleTabChange = useCallback(
    (e, dataSource) => {
      const selectedDataSource = dataSources?.find((ds) => ds.value === dataSource)

      setSelectedDataSource(selectedDataSource)
      const cachedData = logEntriesByDataSource[dataSource]

      if (!cachedData && selectedDataSource?.requestIdField) {
        ;(async () => {
          try {
            setLoading(true)

            const {
              data: { events },
            } = await getAppData(dataSource, 'log', {
              filters: [
                {
                  field: selectedDataSource ? selectedDataSource.requestIdField : null,
                  active: true,
                  operatorId: 'eq',
                  value: requestId,
                },
              ],
            })

            setLogEntriesByDataSource((prev) => ({
              ...prev,
              [dataSource]: events[0],
            }))
          } finally {
            setLoading(false)
          }
        })()
      }
    },
    [dataSources, requestId, logEntriesByDataSource],
  )

  // display the log entry for the currently selected data source tab
  const entry = logEntriesByDataSource[selectedDataSource?.value]

  // get the list of datasources related by request id (or null if the current datasource does not have a request id field) to display as tabs
  const relatedDataSources = useMemo(() => {
    let currentDataSource

    const result = dataSources
      ?.filter((d) => {
        if (d.value === dataSource) {
          currentDataSource = d
          return false
        }

        if (window.location.hostname === 'edge-insights.edgio.app' && d.staging) {
          return false
        }

        if (!staff && d.staffOnly) {
          return false
        }

        return !!d.requestIdField
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
    return currentDataSource?.requestIdField ? [currentDataSource, ...result] : null
  }, [dataSources, dataSource, staff])

  let { common_header = {}, sub_event = [], ...otherData } = entry || {}

  const filter = (obj) => {
    const fields = Object.entries(obj)
      .map(([key, value]) => {
        const metadata = fs.find((f) => f.value === key)
        return { key, value, label: metadata?.label || humanize(key) }
      })
      .sort((a, b) => {
        const labelA = a.label?.toLowerCase()
        const labelB = b.label?.toLowerCase()

        if (labelA < labelB) {
          return -1
        }
        if (labelA > labelB) {
          return 1
        }

        return 0
      })

    if (!search.length) return fields

    return fields.filter((field) => {
      return (
        field.key?.toLowerCase().includes(search.toLowerCase()) ||
        (typeof field.value === 'string' &&
          field.value.toLowerCase().includes(search.toLowerCase())) ||
        field.label?.toLowerCase().includes(search.toLowerCase())
      )
    })
  }

  common_header = filter(common_header)
  otherData = filter(otherData)

  if (!selectedDataSource) {
    return null
  }

  return (
    <Dialog
      open={isOpen}
      onClose={toggle}
      maxWidth="lg"
      PaperProps={{
        sx: {
          minHeight: 'calc(100% - 64px)',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box display="flex">
          <div>Log Details</div>
          <div style={{ flex: 1 }} />
          <IgnoreKeys>
            <TextField
              autoFocus
              variant="outlined"
              size="small"
              placeholder="Find..."
              sx={{ mr: 1, width: 250 }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: <Search sx={{ mr: 1, opacity: 0.6 }} />,
                endAdornment: (
                  <IconButton
                    size="small"
                    sx={{
                      opacity: 0.6,
                      mr: -1,
                      visibility: search.length > 0 ? 'visible' : 'collapse',
                    }}
                    onClick={() => setSearch('')}
                  >
                    <Clear />
                  </IconButton>
                ),
              }}
            />
          </IgnoreKeys>
          <Box display="flex" alignItems="center" sx={{ mr: -1 }}>
            <DownloadDataLink data={data} />
            <Tooltip title="Close">
              <IconButton onClick={toggle} size="small">
                <Close />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {requestId && (
          <Typography variant="caption" sx={{ opacity: 0.6, mt: -1 }}>
            Request ID: {requestId}
          </Typography>
        )}
      </DialogTitle>

      <DialogContent sx={{ overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
        {relatedDataSources && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <Tabs
              value={selectedDataSource.value}
              variant="scrollable"
              scrollButtons={false}
              onChange={handleTabChange}
            >
              {relatedDataSources.map((dataSource) => (
                <Tab
                  key={dataSource.value}
                  value={dataSource.value}
                  label={dataSource.label}
                  sx={{ textTransform: 'none' }}
                />
              ))}
            </Tabs>
          </Box>
        )}
        <Box flex={1} sx={{ overflowY: 'auto' }}>
          {relatedDataSources && <Box mt={3} />}
          {loading ? (
            <Box mx={2}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {Object.keys(common_header).length > 0 && (
                <Fragment>
                  <SectionHeader>Common Headers</SectionHeader>
                  <DataTable data={common_header} fs={fs} />
                </Fragment>
              )}

              {sub_event.length > 0 && (
                <>
                  {sub_event.map((event, index) => (
                    <Fragment key={event?.rule_id || index}>
                      <SectionHeader>Sub Event {index + 1}</SectionHeader>
                      <DataTable
                        data={Object.entries(event).map((key, value) =>
                          Object.fromEntries(
                            new Map([
                              ['key', key],
                              ['value', value],
                            ]),
                          ),
                        )}
                        fs={fs}
                      />
                    </Fragment>
                  ))}
                </>
              )}

              {!entry && (
                <Typography variant="body2" sx={{ opacity: 0.6, mx: 2 }}>
                  No data found
                </Typography>
              )}

              {(Object.keys(common_header).length > 0 || sub_event.length > 0) && (
                <SectionHeader>Other Data</SectionHeader>
              )}

              {Object.keys(otherData).length > 0 && <DataTable data={otherData} fs={fs} />}
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
})

LogModal.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

LogModal.displayName = 'LogModal'
