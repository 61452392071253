import { useSession } from 'next-auth/react'
import React, { useEffect, useMemo, useState } from 'react'
import { HotKeys, configure } from 'react-hotkeys'
import { Box, useTheme } from '@mui/material'
import moment from 'moment-timezone'
import AppContext from './AppContext'
import LoadingPage from './LoadingPage'
import Dashboard from './components/Dashboard/Dashboard'
import EcCharts from './components/Dashboard/EcCharts'
import ErrorBoundary from './components/ErrorBoundary'
import EcControls from './components/GlobalControls/EcControls'
import Feedback from './components/GlobalControls/Feedback'
import GlobalControls from './components/GlobalControls/GlobalControls'
import Header from './components/Header'
import HotkeyHelp from './components/HotkeyHelp'
import Layout from './components/Layout'
import { TmzSetting, getTmz } from './components/TmzSetting'
import { getContext } from './utils/dataFormatting'
import isStaff from './utils/isStaff'
import { useCount, useFields } from './utils/useAppData'
import { applyDataSourceDefaults, getControl, setControls, useControls } from './utils/useControls'

export const defaultSources = [
  {
    label: 'Access Logs',
    description: 'All requests served in the last 6 hours',
    value: 'accesslog-sc-bf',
    t1: 'host',
    t2: 'pop',
    maxInterval: '6h',
    requestIdField: 'req_uuid',
  },
  {
    label: 'Bot Manager Alerts',
    value: 'bot',
    t1: 'host',
    t2: 'pop',
    description: 'All requests that triggered bot alerts in the past 7 days.',
    requestIdField: 'req_uuid',
  },
  {
    label: 'Edge Control',
    value: 'ecMode',
    i: '86400',
    staffOnly: true,
    description: 'All deployments for the last 7 days.',
  },
  {
    label: 'Downsampled Access Logs',
    value: 'accesslog',
    description: '1 out of every 1000 requests served for the past 7 days.',
    t1: 'host',
    t2: 'pop',
    requestIdField: 'req_uuid',
  },
  {
    label: 'Rate Limiting Alerts',
    value: 'rate_limiting',
    description: 'All requests that triggered rate alerts in the past 7 days.',
    t1: 'host',
    t2: 'pop',
    requestIdField: 'req_uuid',
  },
  {
    label: 'WAF Alerts',
    value: 'waf',
    description: 'All requests that triggered WAF alerts in the last 7 days.',
    t1: 'host',
    t2: 'rule_msg',
    requestIdField: 'req_uuid',
  },
  {
    label: 'RUM Logs',
    value: 'rumlog',
    description: 'All Rum Logs in the past 7 days.',
    t1: 'hostname',
    t2: 'destination',
  },
  {
    label: 'Edge Function Logs',
    value: 'edgefunctionslog',
    description: 'All Edge Function Logs in the past 7 days.',
    t1: 'customer_id',
    t2: 'function_name',
    requestIdField: 'req_uuid',
    isMapDisabled: true,
  },
  {
    label: 'Internal Logs (FE)',
    value: 'accesslog-internal',
    description: 'Internal logs FE datasource',
    t1: 'host',
    t2: 'pop',
    staffOnly: true,
    requestIdField: 'req_uuid',
    maxInterval: '6h',
  },
  {
    staging: true,
    label: 'Access Logs (Staging)',
    value: 'accesslog-stage',
    description: 'Staging version of the access logs datasource',
    t1: 'host',
    t2: 'pop',
    requestIdField: 'req_uuid',
  },
  {
    staging: true,
    label: 'Bot Manager Alerts (Staging)',
    value: 'bot-stage',
    t1: 'host',
    t2: 'pop',
    description: 'All requests that triggered bot alerts in the past 7 days.',
    requestIdField: 'req_uuid',
  },
  {
    staging: true,
    label: 'Rate Limiting Alerts (Staging)',
    value: 'rate-limiting-stage',
    description: 'All requests that triggered rate alerts in the past 7 days.',
    t1: 'host',
    t2: 'pop',
    requestIdField: 'req_uuid',
  },
  {
    staging: true,
    label: 'WAF Alerts (Staging)',
    value: 'waf-stage',
    description: 'All requests that triggered WAF alerts in the last 7 days.',
    t1: 'host',
    t2: 'rule_msg',
    requestIdField: 'req_uuid',
  },
  {
    label: 'NEL',
    value: 'bassfish/nel',
    description: 'NEL for the last 7 days.',
    t1: 'host',
    t2: 'original_pop',
    requestIdField: 'req_uuid',
    staffOnly: true,
  },
  {
    label: 'NEL stage',
    value: 'bassfish/stage/nel',
    description: 'NEL for the last 7 days.',
    t1: 'host',
    t2: 'original_pop',
    requestIdField: 'req_uuid',
    staffOnly: true,
    staging: true,
  },
  {
    label: 'Client Side Protection Stage',
    staging: true,
    value: 'bassfish/stage/csp',
    description: 'csp stage',
    t1: 'BlockedUriUrl',
    t2: 'DocumentUriDomain',
    requestIdField: null,
    isMapDisabled: true,
    staffOnly: true,
    maxInterval: '30d',
    minInterval: '1d',
  },
  {
    label: 'CSP',
    value: 'bassfish/csp',
    description: 'Client Side Protection',
    t1: 'BlockedUriUrl',
    t2: 'DocumentUriDomain',
    isMapDisabled: true,
    requestIdField: null,
    staffOnly: true,
    maxInterval: '30d',
  },
  {
    staging: true,
    label: 'RUM Logs (Staging)',
    value: 'rumlog-stage',
    description: 'rum descr',
    t1: 'hostname',
    t2: 'destination',
  },
  {
    staging: true,
    label: 'Edge Function Logs (Staging)',
    value: 'edgefunctionslog-stage',
    description: 'edgefunctionslog descr',
    t1: 'customer_id',
    t2: 'function_name',
    requestIdField: 'req_uuid',
  },
  {
    label: 'Cloud Functions Logs',
    value: 'serverlesslog',
    description: 'Cloud Functions logs datasource',
    t1: 'level',
    t2: 'region',
    maxInterval: '1d',
    requestIdField: 'request_id',
    isMapDisabled: true,
  },
  {
    staging: true,
    label: 'Cloud Functions Logs (Staging)',
    value: 'serverlesslog-stage',
    description: 'Cloud Functions logs datasource',
    t1: 'level',
    t2: 'region',
    maxInterval: '1d',
    requestIdField: 'request_id',
  },
  {
    label: 'Security Logs',
    value: 'security',
    description: 'Security logs datasource',
    t1: 'host',
    t2: 'geoip_country_name',
  },
]

const App = ({ idsDomain }) => {
  configure({ ignoreTags: ['select'] })
  moment.tz.setDefault(getTmz())
  const session = useSession()

  const { dataSource, filters, startTime, endTime } = useControls(
    'dataSource',
    'filters',
    'startTime',
    'endTime',
  )

  const fields = useFields(dataSource, defaultSources)

  const { link: countLink, count: totalEvents } = useCount(dataSource, {
    filters,
    startTime,
    endTime,
  })

  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [isTmzOpen, setIsTmzOpen] = useState(false)
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false)
  const toggleHotkeyHelp = () => setIsHelpOpen((state) => !state)
  const toggleTimeZone = () => setIsTmzOpen((state) => !state)
  const toggleFeedback = () => setIsFeedbackOpen((state) => !state)

  const keyMap = {
    ZHELP: { name: 'Show this hotkey help', sequence: 'shift+?' },
  }

  const handlers = {
    ZHELP: toggleHotkeyHelp,
  }

  const top = 82
  const theme = useTheme()
  const [dataSources, setDataSources] = useState(null)

  // Fetch the list of datasources from the console
  useEffect(() => {
    const ds = getControl('dataSource')

    const fetchDataSourcesFromConsole = async (defaultSources) => {
      const consoleUrlBase = process.env.CONSOLE_URL_BASE
      let res
      try {
        res = await fetch(`${consoleUrlBase}/edge-insights/datasources`, {
          credentials: 'include',
        })
      } catch (e) {}

      if (res && res.ok) {
        const expandedDataSources = defaultSources.concat(await res.json())
        setDataSources(expandedDataSources)
        let dataSource = expandedDataSources.find(({ value }) => value === ds)

        if (!dataSource) {
          // If the user has not previously selected a datasource, default to Events datasource
          dataSource = expandedDataSources.find(({ value }) => value === 'console/Events')

          setControls({ dataSource: dataSource?.value || 'accesslog-sc-bf' })
        }
        if (dataSource) {
          applyDataSourceDefaults(dataSource)
        }
      }
    }

    const context = getContext()

    setDataSources(defaultSources)
    if (context?.admin || (!context && isStaff(session))) {
      // Only load console datasources if the user clicked the Edge Insights link in the Console Administrator view
      // Or if the user is staff and did not come from console.
      try {
        fetchDataSourcesFromConsole(defaultSources)
      } catch (e) {}
    } else {
      applyDataSourceDefaults(defaultSources.find(({ value }) => value === ds))
    }
  }, [])

  const appContext = useMemo(() => ({ dataSources, fields }), [dataSources, fields])

  if (fields == null || dataSources == null) {
    // Show the loading page until we have gotten back the list of fields. Until that happens we do not know that
    // we have a valid session.
    return <LoadingPage />
  }

  return (
    <AppContext.Provider value={appContext}>
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <Header
          idsDomain={idsDomain}
          toggleHotkeyHelp={toggleHotkeyHelp}
          toggleTimeZone={toggleTimeZone}
          toggleFeedback={toggleFeedback}
        />
        <Layout>
          <HotkeyHelp isOpen={isHelpOpen} toggle={toggleHotkeyHelp} />
          <TmzSetting isOpen={isTmzOpen} toggle={toggleTimeZone} />
          <Feedback isOpen={isFeedbackOpen} toggle={toggleFeedback} />

          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                width: 380,
                position: 'fixed',
                top,
                maxHeight: `calc(100vh - ${top}px - 16px)`,
                overflowY: 'auto',
                background: theme.palette.appBar,
              }}
            >
              {dataSource === 'ecMode' ? (
                <EcControls countLink={countLink} totalEvents={totalEvents} />
              ) : (
                <GlobalControls
                  countLink={countLink}
                  fields={fields}
                  datasource={dataSource}
                  data={{
                    count: totalEvents,
                    time_from: startTime,
                    time_to: endTime,
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                overflowY: 'auto',
                p: 1,
                pt: `${top}px`,
                marginLeft: '392px',
                flex: 1,
                borderLeft: `1px solid ${theme.palette.bodyDivider}`,
                minHeight: `100vh`,
                padding: theme.spacing(2.5),
                background: theme.palette.background.main,
              }}
            >
              <ErrorBoundary>
                {dataSource === 'ecMode' ? (
                  <EcCharts fields={fields} />
                ) : (
                  <Dashboard fields={fields} />
                )}
              </ErrorBoundary>
            </Box>
          </Box>
        </Layout>
      </HotKeys>
    </AppContext.Provider>
  )
}

export default App
