import { signOut } from 'next-auth/react'
import React, { memo, useCallback, useContext } from 'react'
import MoonIcon from '@mui/icons-material/Brightness4'
import SunIcon from '@mui/icons-material/Brightness7'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import packageJson from '../../package.json'
import DarkModeContext from '../DarkModeContext'
import { getContext } from '../utils/dataFormatting'
import { StaffOnly } from '../utils/isStaff'
import Breadcrumbs from './Breadcrumbs'
import Logo from './Logo'

const Header = ({ toggleHotkeyHelp, toggleTimeZone, toggleFeedback }) => {
  const { darkMode, setDarkMode } = useContext(DarkModeContext)
  const theme = useTheme()
  const Tag = darkMode ? SunIcon : MoonIcon
  const context = getContext()

  const handleLogOut = useCallback(async () => {
    await fetch(`/api/sign-out`)
    signOut()
  }, [])

  return (
    <AppBar
      position="fixed"
      sx={{
        background: theme.palette.background.appBar,
        color: theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.bodyDivider}`,
        boxShadow: 'none',
      }}
    >
      <Toolbar sx={{ gap: 1, padding: '0 13px !important' }}>
        <div style={{ minWidth: 387 }}>
          <Logo logoUrl={context ? context.logoUrl : null} />
        </div>
        {context && <Breadcrumbs />}
        <div style={{ flex: 1 }} />
        <Box gap={2} display="flex" alignItems="center">
          <StaffOnly>
            <Typography
              component="div"
              variant="caption"
              sx={{
                opacity: 0.6,
                color: theme.palette.text.primary,
              }}
            >
              v{packageJson.version}
            </Typography>
          </StaffOnly>
          <Tooltip title="Toggle dark/light mode">
            <IconButton size="small" onClick={() => setDarkMode(!darkMode)} color="inherit">
              <Tag />
            </IconButton>
          </Tooltip>
          <StaffOnly>
            <Button onClick={toggleFeedback} color="inherit">
              Feedback
            </Button>
          </StaffOnly>
          <Button onClick={toggleTimeZone} color="inherit">
            Time Zone
          </Button>
          <Button onClick={toggleHotkeyHelp} color="inherit">
            Hot Keys
          </Button>
          <Button onClick={handleLogOut} color="inherit">
            Log Out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default memo(Header)
