import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

const Layout = (props) => {
  const { children } = props

  return (
    <Box sx={{ mx: 2 }}>
      <div
        style={{
          position: 'relative',
          width: 'calc(100% + 2.5rem)',
        }}
      >
        <ToastContainer
          style={{ width: '500px' }}
          position="top-right"
          autoClose={5000}
          transition={Slide}
          draggable={false}
          closeOnClick={false}
          pauseOnHover
        />
      </div>
      {children}
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
