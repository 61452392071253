import React, { Fragment, useEffect } from 'react'
import {
  Box,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import { setControls, useControls } from '../../utils/useControls'
import FieldAutocomplete from '../FieldAutocomplete'
import HotKeySelect from '../HotKeySelect'

export const chartTypes = [
  {
    label: 'Percentiles',
    value: 'percentiles',
  },
  {
    label: 'Percentiles by Time',
    value: 'historical_percentiles',
  },
  {
    label: 'Stats',
    value: 'stats',
  },
  {
    label: 'Stats by Time',
    value: 'historical_stats',
  },
]

export const analyticsForDatasources = {
  default: ['percentiles', 'historical_percentiles', 'stats', 'historical_stats'],
  'bassfish/nel': ['stats'],
  'bassfish/stage/nel': ['stats'],
}

export const wafTypes = [
  'ALERT',
  'BLOCK_REQUEST',
  'CUSTOM_RESPONSE',
  'DROP_REQUEST',
  'REDIRECT_302',
]
const Label = styled(Typography)(({ theme }) => ({
  opacity: 0.6,
  ...theme.typography.body2,
}))

const SmallSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    ...theme.typography.body2,
    paddingTop: 5,
    paddingBottom: 5,
  },
}))

export const isChartAvailable = (chartType, dataSource) => {
  return analyticsForDatasources[dataSource]
    ? analyticsForDatasources[dataSource].includes(chartType.value)
    : true
}

const Analytics = ({ fields, setAnalyticsChart, histogramType }) => {
  const { dataSource, logMode, hMode, selectedTopField, selectedWafType, field, quantiles } =
    useControls(
      'dataSource',
      'logMode',
      'hMode',
      'selectedTopField',
      'selectedWafType',
      'field',
      'quantiles',
    )

  const theme = useTheme()
  const toggleLog = () => setControls({ logMode: !logMode })
  const toggleVertical = () => setControls({ hMode: !hMode })
  const resolutions = ['Second', 'Minute', 'Hour', 'Day']
  dataSource !== 'analytics' && resolutions.unshift('Auto')
  const setSelectedWafType = (value) => {
    setControls({
      selectedWafType: value,
    })
  }
  const setSelectedChartType = ({ value }) => {
    setAnalyticsChart(value)
    setControls({
      histogramType: value,
      ...(value.includes('historical_') && { selectedTopField: 0 }),
    })
  }

  useEffect(() => {
    if (!selectedWafType) {
      setSelectedWafType(wafTypes[0])
    }
  }, [])

  useEffect(() => {
    if (selectedTopField === 0) {
      setControls({ layout: 'single' })
    }
  }, [selectedTopField])
  return (
    <Fragment>
      <Box display="flex" flexDirection="column">
        <Label gutterBottom>Chart Type</Label>
        <HotKeySelect
          Component={SmallSelect}
          hotKeyHelp="Show the chart type dropdown options"
          hotKeyName="TCHART"
          hotKeySequence="t"
          size="small"
          fullWidth
          sx={{
            fontSize: theme.typography.body2.fontSize,
            mb: 0.5,
          }}
          value={histogramType}
          onChange={(e) =>
            setSelectedChartType(
              chartTypes.find(
                (item) => item.value === e.target.value && isChartAvailable(item, dataSource),
              ),
            )
          }
        >
          {chartTypes
            .filter((chartType) => isChartAvailable(chartType, dataSource))
            .map((type) => (
              <MenuItem key={type.value} value={type.value} size="small">
                {type.label}
              </MenuItem>
            ))}
        </HotKeySelect>
      </Box>
      <Box display="flex" flexDirection="column">
        <Label gutterBottom>Field</Label>
        <FieldAutocomplete
          key={fields}
          disableClearable
          control="field"
          value={field}
          className="tiny"
          fields={fields.filter((i) => i.isAnalyzed === true)}
          inputProps={{
            fullWidth: true,
            sx: { minWidth: 200 },
          }}
        />
      </Box>

      {histogramType === 'percentiles' && (
        <Box display="flex" flexDirection="column">
          <Label gutterBottom>Scale</Label>
          <ToggleButtonGroup exclusive value={logMode} color="primary">
            <ToggleButton size="small" onClick={() => toggleLog(false)} value={false}>
              Linear
            </ToggleButton>
            <ToggleButton size="small" onClick={() => toggleLog(true)} value={true}>
              Log
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      {histogramType === 'percentiles' && (
        <Box display="flex" flexDirection="column">
          <Label gutterBottom>Orient</Label>
          <ToggleButtonGroup exclusive value={hMode} color="primary">
            <ToggleButton size="small" onClick={() => toggleVertical(false)} value={false}>
              V
            </ToggleButton>
            <ToggleButton size="small" onClick={() => toggleVertical(true)} value={true}>
              H
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
      {(dataSource === 'waf' || dataSource === 'waf-stage') && (
        <Box display="flex" flexDirection="column">
          <Label gutterBottom>WAF Type</Label>
          <HotKeySelect
            Component={SmallSelect}
            hotKeyHelp="Show the chart type dropdown options"
            hotKeyName="TCHART"
            hotKeySequence="t"
            size="small"
            fullWidth
            sx={{
              fontSize: theme.typography.body2.fontSize,
              mb: 0.5,
            }}
            value={selectedWafType}
            onChange={(e) => setSelectedWafType(e.target.value)}
          >
            {wafTypes.map((wafType) => (
              <MenuItem key={wafType} value={wafType} size="small">
                {wafType}
              </MenuItem>
            ))}
          </HotKeySelect>
        </Box>
      )}
      {histogramType !== 'stats' && (
        <Box display="flex" flexDirection="column" size="small">
          <Typography variant="body2" gutterBottom>
            Quantiles
          </Typography>
          <ToggleButtonGroup
            exclusive
            color="primary"
            size="small"
            value={String(quantiles) || '10'}
            onChange={(e) => setControls({ quantiles: e.target.value })}
          >
            <ToggleButton value={'10'}>10</ToggleButton>
            <ToggleButton value={'25'}>25</ToggleButton>
            <ToggleButton value={'50'}>50</ToggleButton>
            <ToggleButton value={'100'}>100</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
    </Fragment>
  )
}

Analytics.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  fields: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Analytics
