import { useSession } from 'next-auth/react'

/**
 * Returns true if the user is an Edgio employee
 * @param {*} session A next-auth session object
 * @returns
 */
export default function isStaff(session) {
  try {
    return session.data.user.email.match(/@(edg.io|llnw.com|edgecast.com)$/)
  } catch (e) {
    // may get here if the session is expired.
    window.location.reload()
    return false
  }
}

export const StaffOnly = ({ children }) => {
  const session = useSession()

  if (isStaff(session)) {
    return children
  } else {
    return null
  }
}
