import React, { useState } from 'react'
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps'
import { Box, Popover, useTheme } from '@mui/material'
import { formatNumber } from '../../../utils/dataFormatting'
import WorldMapJson from './WorldMapJson.json'

const popFieldNameMap = {
  default: 'pop',
  'bassfish/nel': 'original_pop',
  'bassfish/stage/nel': 'original_pop',
}
const ipFieldNameMap = { default: 'virt_remote_host', 'bassfish/nel': 'client_ip_prefix' }

export const WorldMap = ({
  data,
  dataSource,
  mapType,
  addFilter,
  position = {},
  setPosition = () => {},
}) => {
  const theme = useTheme()
  const [annotation, setAnnotation] = useState(false)
  const sumTotal = data.reduce((sum, { count }) => sum + count, 0)

  const handleMarkerClick = {
    pop: (term) =>
      addFilter(
        popFieldNameMap[dataSource] ? popFieldNameMap[dataSource] : popFieldNameMap.default,
        term,
        'eq',
      ),
    srcip: (src_ip) =>
      addFilter(
        ipFieldNameMap[dataSource] ? ipFieldNameMap[dataSource] : ipFieldNameMap.default,
        src_ip,
        'eq',
      ),
  }

  return (
    <div className="position-relative">
      {annotation && (
        <Popover
          open={annotation}
          anchorEl={annotation.el}
          closeAfterTransition={false}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: -10, horizontal: -10 }}
          sx={{ opacity: '0.75', pointerEvents: 'none' }}
        >
          <Box sx={{ px: 2, py: 1 }}>
            <strong>{annotation.title}</strong>:&nbsp;&nbsp;
            {formatNumber(annotation.count)}&nbsp;events&nbsp;&nbsp;
            <span style={{ opacity: 0.5, fontWeight: 100 }}>|</span>
            &nbsp;&nbsp;
            {(annotation.alerts * 100).toFixed(2).padStart(3, '0')}%
          </Box>
        </Popover>
      )}
      <ComposableMap
        style={{ backgroundColor: '#bbcfdd' }}
        width={800}
        height={450}
        projection="geoMercator"
        projectionConfig={{
          rotate: [-10, 0, 0],
        }}
      >
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={(position) => setPosition(position)}
          filterZoomEvent={(evt) => evt.type !== 'wheel'}
        >
          <Geographies geography={WorldMapJson}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={'#eeeeee'}
                  stroke={'#bdbdbd'}
                  strokeWidth="0.5px"
                />
              ))
            }
          </Geographies>
          {data.map(({ coordinates, count, ...props }) => {
            const key = {
              pop: props.term,
              srcip: props.src_ip,
            }[mapType]

            const title = {
              pop: props.term?.toUpperCase(),
              srcip: props.src_ip,
            }[mapType]

            const alerts = count / sumTotal

            return key !== undefined ? (
              <Marker key={key} coordinates={coordinates}>
                <g
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleMarkerClick[mapType](key)}
                  onMouseEnter={(e) => {
                    // Fixes the issue where the page will scroll back to the previously focused element after the annotation popup is removed
                    document.activeElement?.blur()
                    setAnnotation({
                      title,
                      count,
                      alerts,
                      el: e.target,
                    })
                  }}
                  onMouseLeave={() => setAnnotation(false)}
                  fill={theme.palette.secondary.dark}
                  stroke={theme.palette.secondary.dark}
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  opacity="0.5"
                >
                  <circle r={Math.max(alerts * 80, 3)} />
                </g>
              </Marker>
            ) : null
          })}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  )
}
