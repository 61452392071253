import { useEffect, useState } from 'react'
import moment from 'moment'
import { operators } from '../components/GlobalControls/Filters/FilterOption'

// Translates params to/from app data structures
export const controlsToParams = {
  dataSource: {
    param: 'vt',
    initial: 'accesslog-sc-bf',
  },
  avgMode: {
    param: 'avg',
    initial: '0',
    formatIncoming: (value) => Boolean(JSON.parse(value)),
    formatOutgoing: (value) => Number(value),
  },
  logMode: {
    param: 'log',
    initial: '0',
    formatIncoming: (value) => Boolean(JSON.parse(value)),
    formatOutgoing: (value) => Number(value),
  },
  hMode: {
    param: 'h',
    initial: '0',
    formatIncoming: (value) => Boolean(JSON.parse(value)),
    formatOutgoing: (value) => Number(value),
  },
  legendMode: {
    param: 'legend',
    initial: '0',
    formatIncoming: (value) => Boolean(JSON.parse(value)),
    formatOutgoing: (value) => Number(value),
  },
  nowMode: {
    param: 'nm',
    initial: 'false',
    formatIncoming: JSON.parse,
  },
  refreshRate: {
    param: 'ttr',
    initial: '10',
    formatIncoming: JSON.parse,
  },
  topN: {
    param: 'topn',
    initial: '10',
    formatIncoming: JSON.parse,
  },
  filters: {
    param: 'fstatus',
    initial: '{}',
    formatIncoming: (filterStatus) =>
      Object.entries(JSON.parse(filterStatus)).map(
        ([_index, { field, value, active, operatorId }]) => ({
          field,
          value,
          active,
          operatorId,
        }),
      ),
    formatOutgoing: (filterStatus) => filterStatus,
  },
  startTime: {
    param: 'start_time',
    initial: () => {
      const interval = getControl('interval')
      return moment().unix() - (interval ?? 3600)
    },
    formatIncoming: JSON.parse,
  },
  endTime: {
    param: 'end_time',
    initial: moment().unix(),
    formatIncoming: JSON.parse,
  },
  interval: {
    param: 'ti',
    initial: '3600',
    formatIncoming: JSON.parse,
  },
  histogramType: {
    param: 'selectedChart',
    initial: 'histogram',
  },
  field: {
    param: 'selectedQuantitativeField',
    initial: 'bytes_in',
  },
  layout: {
    param: 'l',
    initial: 'single',
  },
  prefix: {
    param: 'prefix',
    initial: '',
  },
  quantiles: {
    param: 'quantiles',
    initial: '',
  },
  resolution: {
    param: 'bin',
    initial: 'auto',
  },
  selectedTopField: {
    param: 'gt',
    initial: 'blocks_all',
    formatIncoming: (value) => parseInt(value.replace(/\D/g, ''), 10) || 0,
    formatOutgoing: (index) => (index ? `chart${index}` : 'blocks_all'),
  },
  selectedWafType: {
    initial: 'ALERT',
  },
  topField1: {
    param: 'pc1s',
  },
  topField2: {
    param: 'pc2s',
  },
  mapType: {
    param: 'mt',
    initial: 'graph_by_pop',
    formatIncoming: (mapType) => mapType.replace('graph_by_', ''),
    formatOutgoing: (mapType) => `graph_by_${mapType}`,
  },
  perPage: {
    param: 'pp',
    initial: '10',
    formatIncoming: JSON.parse,
  },
  currentPage: {
    param: 'p',
    initial: '1',
    formatIncoming: JSON.parse,
  },
  vs: {
    param: 'vs',
    initial: '',
    formatIncoming: (str) => (str !== '' ? str.split(',') : null),
    formatOutgoing: (arr) => (arr.length ? arr.join(',') : ''),
  },
}

export const getControl = (control) => {
  try {
    const { param, initial, formatIncoming } = controlsToParams[control]
    const paramValue = new URLSearchParams(window.location.search).get(param)

    let value = paramValue ?? localStorage.getItem(param)

    if (value == null) {
      value = typeof initial === 'function' ? initial() : initial
    }

    if (formatIncoming) {
      value = formatIncoming(value)
    }

    return value
  } catch (error) {
    console.error(`${control}: ${error}`)
  }
}

// Takes a list of string arguments corresponding to the properties that will
// be returned in the same order.
export const useControls = (...controlNames) => {
  const getControls = () =>
    controlNames.reduce((result, control) => {
      result[control] = getControl(control)
      return result
    }, {})

  const [value, setValue] = useState(getControls)

  useEffect(() => {
    const onChange = () => {
      setValue(getControls())
    }
    window.addEventListener('pushstate', onChange)

    return () => {
      window.removeEventListener('pushstate', onChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return value
}

// Expects an object with { controlName: value } structure
export const setControls = (controls) => {
  const params = new URLSearchParams(window.location.search)

  Object.entries(controls).forEach(([control, value]) => {
    if (control === 'filters') {
      const filterStatus = value
      params.set('f', JSON.stringify(value))

      params.set('fstatus', JSON.stringify(filterStatus))
    } else {
      const controlParams = controlsToParams[control]

      if (controlParams) {
        const { param, formatOutgoing } = controlParams
        const paramValue = value

        if (formatOutgoing) {
          params.set(param, formatOutgoing(paramValue))
        } else {
          if (
            [
              'ti',
              'selectedChart',
              'selectedQuantitativeField',
              'prefix',
              'quantiles',
              'bin',
            ].includes(param)
          ) {
            localStorage.setItem(param, paramValue)
          }
          params.set(param, paramValue)
        }
      }
    }
  })
  // if (controls.isNowQuery) {
  //   params.delete('start_time')
  //   params.delete('end_time')
  // }
  params.sort()
  const { pathname } = window.location
  window.history.pushState(null, '', `${pathname}?${params.toString()}`)
  window.dispatchEvent(new Event('pushstate'))
}

export const editFilter = (index, filter) => {
  const filters = getControl('filters')
  filters.splice(index, 1, filter)

  setControls({ filters })
  setControls({
    currentPage: 1,
  })
}

export const removeFilter = (filter) => {
  const name = 'filters'
  const filters = getControl(name)
  const removed = filters.filter((i) => {
    return !(i.field === filter.field && i.operatorId === filter.operatorId)
  })
  setControls({ [name]: removed })
}

export const addFilter = (field, value, operatorId) => {
  const filters = getControl('filters')

  if (
    filters.some(
      (i) =>
        i.field === field &&
        !(
          [operators.le.id, operators.ge.id].includes(i.operatorId) &&
          [operators.le.id, operators.ge.id].includes(operatorId)
        ),
    )
  ) {
    removeFilter({ field, operatorId })
  }

  const name = 'filters'
  const list = getControl(name)
  value = String(value)
  list.push({ field, value, active: true, operatorId })
  setControls({ [name]: list })

  setControls({
    currentPage: 1,
  })
}

// Fills the URL search params when values are missing
export const setInitialParams = () => {
  const controls = Object.keys(controlsToParams).reduce((result, control) => {
    result[control] = getControl(control)
    return result
  }, {})

  setControls(controls)
}

export const applyDataSourceDefaults = (dataSource) => {
  const { value, h, p, r, q, t1, t2, maxInterval, defaultAnalyzed } = dataSource

  const controls = {
    dataSource: value,
    histogramType: getControl('histogramType') || h || controlsToParams.histogramType.initial,
    resolution: getControl('resolution') || r || controlsToParams.resolution.initial,
    prefix: getControl('prefix') || p || controlsToParams.prefix.initial,
    quantiles: getControl('quantiles') || q || controlsToParams.quantiles.initial,
    field: getControl('field') || defaultAnalyzed || controlsToParams.field.initial,
    maxInterval,
    topField1: getControl('topField1') || t1,
    topField2: getControl('topField2') || t2,
  }

  const interval = getControl('interval')

  if (interval > maxInterval) {
    Object.assign(controls, {
      interval: maxInterval,
      startTime: moment().unix() - interval,
      endTime: moment().unix(),
    })
  }

  setControls(controls)
}
