import React from 'react'
import { TextField } from '@mui/material'
import moment from 'moment'
import { setControls, useControls } from '../../utils/useControls'

const isFirefox = typeof InstallTrigger !== 'undefined'

const TimeRange = () => {
  const { startTime, endTime } = useControls('startTime', 'endTime')

  const timeStampToDate = (v) => {
    if (v === undefined) {
      return isFirefox ? moment().toDate() : moment().format()
    } else if (typeof v === 'string') {
      return isFirefox
        ? moment().subtract(30, 'days').toDate()
        : moment().subtract(30, 'days').format()
    } else {
      return isFirefox ? moment.unix(v).toDate() : moment.unix(v).format()
    }
  }

  const timeStampToIsoStr = (value) => {
    let d = timeStampToDate(value)
    return moment(d).format(moment.HTML5_FMT.DATETIME_LOCAL)
  }

  const setStartTime = (e) => {
    const ts = e.target ? moment(e.target.value).unix() : moment(e).unix()
    if (ts < endTime) {
      setControls({
        startTime: ts,
      })
    }
  }

  const setEndTime = (e) => {
    const ts = e.target ? moment(e.target.value).unix() : moment(e).unix()
    if (ts > startTime) {
      setControls({
        endTime: ts,
      })
    }
  }

  const DatePicker = ({ isStart, ...props }) => {
    return (
      <TextField
        fullWidth
        label={isStart ? 'Start' : 'End'}
        size="small"
        variant="outlined"
        type="datetime-local"
        className="form-control"
        min={timeStampToIsoStr(isStart ? '30' : startTime)}
        max={timeStampToIsoStr()}
        value={timeStampToIsoStr(isStart ? startTime : endTime)}
        onChange={isStart ? setStartTime : setEndTime}
        onKeyDown={(e) => e.preventDefault()}
        name={isStart ? 'Start Date' : 'End Date'}
        {...props}
      />
    )
  }

  return (
    <form>
      <DatePicker isStart={true} sx={{ mb: 2 }} />
      <DatePicker isStart={false} sx={{ mb: 1 }} />
    </form>
  )
}

export default TimeRange
