import { memo, useCallback, useRef, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { Select as MuiSelect } from '@mui/material'
import PropTypes from 'prop-types'

/**
 * MUI's select wrapped in hot key functionality.
 */
function HotKeySelect({ hotKeySequence, hotKeyName, hotKeyHelp, Component, ...props }) {
  const [open, setOpen] = useState(false)
  const inputRef = useRef()

  const keyMap = {
    [hotKeyName]: { name: hotKeyHelp, sequence: hotKeySequence },
  }

  const toggle = useCallback(() => {
    setOpen((open) => {
      if (inputRef.current && !open) {
        inputRef.current.focus()
      }
      return !open
    })
  }, [])

  const handlers = { [hotKeyName]: toggle }

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'd') {
        toggle()
      }
    },
    [toggle],
  )

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      <Component
        {...props}
        open={open}
        onKeyDown={handleKeyDown}
        inputProps={{ ...props.inputProps, ref: inputRef }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      />
    </GlobalHotKeys>
  )
}

HotKeySelect.propTypes = {
  ...MuiSelect.propTypes,
  /**
   * The sequence that triggers focusing this component
   */
  hotKeySequence: PropTypes.string,
  /**
   * A description for the hotkey help menu
   */
  hotKeyHelp: PropTypes.string,
  /**
   * A unique name given to this component
   */
  hotKeyName: PropTypes.string,

  Component: PropTypes.elementType,
}

HotKeySelect.defaultProps = {
  Component: MuiSelect,
}

export default memo(HotKeySelect)
