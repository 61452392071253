import React, { forwardRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import sort from '../../../utils/sort'
import { addFilter, editFilter } from '../../../utils/useControls'
import FilterButton from './FilterButton'
import FilterForm from './FilterForm'

export const operators = {
  eq: { id: 'eq', hover: 'Equal to', symbol: '\u003D' },
  ne: { id: 'ne', hover: 'Not equal', symbol: '\u2260' },
  le: {
    id: 'le',
    hover: 'Less or equal',
    symbol: '\u2264',
    applicableTypes: ['int', 'double', 'integer'],
  },
  ge: {
    id: 'ge',
    hover: 'Greater or equal',
    symbol: '\u2265',
    applicableTypes: ['int', 'double', 'integer'],
  },
}

const FilterOption = forwardRef((props, forwardedRef) => {
  const {
    index,
    filterData = { field: '', value: '', type: '', operatorId: operators.eq.id },
    isEditing,
    selectFilter,
    selectedFields,
    formattedFields,
    datasource,
  } = props

  const [filterName, setFilterName] = useState(filterData.field)
  const [filterValue, setFilterValue] = useState(filterData.value)
  const [fieldType, setFieldType] = useState(filterData.type)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [operatorId, setOperatorId] = useState(filterData.operatorId)

  const selectedField = formattedFields.find((field) => field.value === filterName)
  const filteredSelectedFields = selectedFields.filter(
    (field) =>
      (!selectedField || field !== selectedField.value) &&
      (operatorId === 'ge' || operatorId === 'le'),
  )
  const filteredOptions = useMemo(() => {
    return sort(formattedFields.filter((field) => !filteredSelectedFields.includes(field.value)))
  }, [formattedFields, filteredSelectedFields])

  const saveFilter = (event) => {
    event.preventDefault()
    const hasName = Boolean(filterName.length)
    const hasValue = Boolean(filterValue.length)
    const hasNewName = filterData.name !== filterName
    const hasNewValue = filterData.value !== filterValue
    const hasNewOperation = filterData.operatorId !== operatorId
    setIsSubmitted(true)

    if (hasName && hasValue && operatorId && (hasNewName || hasNewValue || hasNewOperation)) {
      if ([undefined, null].includes(index)) {
        addFilter(filterName, filterValue, operatorId)
      } else {
        editFilter(index, {
          field: filterName,
          value: filterValue,
          active: filterData.active,
          operatorId: operatorId,
        })
      }
      selectFilter(false)
    }
  }

  const toggleFilter = (i) => {
    editFilter(i, {
      ...filterData,
      active: !filterData.active,
    })
  }

  return isEditing ? (
    <FilterForm
      ref={forwardedRef}
      filteredOptions={filteredOptions}
      existingFilter={filterData}
      filterName={filterName}
      setFilterName={setFilterName}
      filterValue={filterValue}
      setFilterValue={setFilterValue}
      fieldType={fieldType}
      setFieldType={setFieldType}
      selectedField={selectedField}
      saveFilter={saveFilter}
      selectFilter={selectFilter}
      isSubmitted={isSubmitted}
      operatorId={operatorId}
      setOperatorId={setOperatorId}
      datasource={datasource}
    />
  ) : (
    <FilterButton
      index={index}
      filterData={filterData}
      selectedField={selectedField}
      selectFilter={selectFilter}
      toggleFilter={toggleFilter}
    />
  )
})

FilterOption.displayName = 'FilterOption'

FilterOption.propTypes = {
  filterData: PropTypes.shape({
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    active: PropTypes.bool.isRequired,
    operatorId: PropTypes.string,
  }),
  isEditing: PropTypes.bool.isRequired,
  selectFilter: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default FilterOption
