import React, { Fragment, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import { formatNumber, parseFieldSelection } from '../../utils/dataFormatting'
import { StaffOnly } from '../../utils/isStaff'
import { addFilter, editFilter, setControls, useControls } from '../../utils/useControls'
import { SEPERATOR, useFieldsData } from '../../utils/useEcData'
import Intervals from './Intervals'
import Navigate from './Navigate'
import { SourceSelector } from './SourceSelector'
import TimeRange from './TimeRange'

const EcControls = () => {
  const { fieldsInfo, fieldsData, isFieldsLoading } = useFieldsData()
  const { filters, vs } = useControls('filters', 'vs')
  const [anFilter, setAnFilter] = useState(filters[0]?.value || '')
  const [selected, setSelected] = useState(vs)
  const parsedSelected = parseFieldSelection(selected, SEPERATOR)

  const onSubmitAnFilter = (e) => {
    if (e.key === 'Enter') {
      if (filters.length) {
        editFilter(0, { field: 'an', value: e.target.value, operatorId: 'eq', active: true })
      } else {
        addFilter('an', e.target.value, 'eq')
      }
      setControls({ vs: '' })
      setSelected(null)
    }
  }

  const onSelected = (e) => {
    if (e.target.checked) {
      setSelected((prev) => (prev ? [...prev, e.target.id] : [e.target.id]))
    } else {
      setSelected((prev) => prev.filter((item) => item !== e.target.id))
    }
  }

  const onSubmit = () => {
    setControls({ vs: selected })
  }

  const LoadText = () => {
    return (
      <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
        <CircularProgress size={16} sx={{ mr: 1 }} />
        <div>Loading...</div>
      </Box>
    )
  }

  const SelectionDiv = ({ d, idx }) => {
    if (d.errors) {
      return (
        <Typography color="error" variant="body2">
          {d.errors[0]?.message}
        </Typography>
      )
    } else {
      if (d.results.length === 0) {
        return (
          <Typography color="error" variant="body2">
            No data was found for this customer AN.
          </Typography>
        )
      } else if (d.results.length === 1 && idx === 0) {
        return (
          <Fragment>
            <div className="mb-4">
              <Typography color="error" variant="body2">
                Changing the time range to get more than one version
              </Typography>
            </div>
            {d.results.map((r, i) => (
              <div className="custom-control custom-checkbox" key={i}>
                <span className="w-100">
                  {r.term}
                  <small className="text-muted float-right">{formatNumber(r.count)}</small>
                </span>
              </div>
            ))}
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            {d.results
              .sort((a, b) => a.term.toString().localeCompare(b.term.toString()))
              .map((r, i) => (
                <div className="custom-control custom-checkbox" key={i}>
                  <FormControlLabel
                    sx={{ display: 'flex' }}
                    control={
                      <Checkbox
                        id={idx + SEPERATOR + r.term}
                        name={r.term.toString()}
                        checked={selected?.includes(idx + SEPERATOR + r.term)}
                        onChange={onSelected}
                      />
                    }
                    label={
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography>{r.term}</Typography>
                        <Typography variant="caption" sx={{ opacity: 0.8, ml: 1 }}>
                          ({formatNumber(r.count)} events)
                        </Typography>
                      </Box>
                    }
                  />
                </div>
              ))}
          </Fragment>
        )
      }
    }
  }

  return (
    <>
      <SourceSelector />
      <Intervals />
      <TimeRange />
      <Navigate />
      <StaffOnly>
        <div>
          <Typography sx={{ mb: 1 }}>
            <b>Filters</b>
          </Typography>

          <TextField
            fullWidth
            value={anFilter}
            label="Customer AN"
            onChange={(e) => setAnFilter(e.target.value)}
            size="small"
            variant="outlined"
            onKeyDown={onSubmitAnFilter}
            error={!anFilter}
            helperText={anFilter ? null : 'This field is required'}
          />
        </div>
      </StaffOnly>
      {isFieldsLoading ? (
        anFilter !== '' && <LoadText />
      ) : (
        <Fragment>
          {fieldsInfo.map((field, idx) => (
            <Box key={idx} sx={{ mt: 2 }}>
              <Typography>
                <b>{field.name}</b>
              </Typography>
              <SelectionDiv d={fieldsData[idx].data} idx={idx} />
            </Box>
          ))}
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              size="small"
              sx={{ mt: 1 }}
              disabled={
                !(
                  parsedSelected &&
                  parsedSelected[0]?.length > 1 &&
                  (parsedSelected[1]?.length > 0 || parsedSelected[2]?.length > 0)
                )
              }
              onClick={onSubmit}
            >
              Analyze
            </Button>
          </Box>
        </Fragment>
      )}
    </>
  )
}

export default EcControls
